<template>
  <div
      v-if="loading"
      class="d-flex flex-row justify-content-center"
      style="margin-top: 250px; margin-bottom: 200px"
    >
      <HalfCircleSpinner
        :animation-duration="1000"
        :dot-size="35"
        :dots-num="3"
        color="var(--primary-color)"
      />
    </div>
  <div id="app" v-else>
    <top-header-component></top-header-component>
    <header-component></header-component>
    <vue-page-transition name="fade-in-down">
      <router-view />
    </vue-page-transition>
    <footer-component></footer-component>
  </div>
</template>

<script>
import TopHeaderComponent from "./components/TopHeader.vue";
import HeaderComponent from "./components/Header.vue";
import FooterComponent from "./components/Footer.vue";

export default {
  components: { TopHeaderComponent, HeaderComponent, FooterComponent },

  data: function() {
    return {
      DBitems: null,
      loading: false
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get("https://dekakeen.com/api/website/setting_website")
      .then(response => {
        if (response.data.code == "1") {
          this.DBitems = response.data.data;
          var r = document.querySelector(":root");
          r.style.setProperty("--primary-color", this.DBitems.primary_color);
          r.style.setProperty("--third-color", this.DBitems.secondry_color);
          this.$store.state.title_ar = this.DBitems.title_ar;
          this.$store.state.title_en = this.DBitems.title_en;


          this.$store.state.description_ar = this.DBitems.description_ar;
          this.$store.state.description_en = this.DBitems.description_en;


          this.$store.state.keyword_ar = this.DBitems.keyword_ar;
          this.$store.state.keyword_en = this.DBitems.keyword_en;


          document.getElementById("description").content=
          this.DBitems.description_ar;


        document.getElementById("keyword_meta").content=this.DBitems.keyword_ar;


        document.title = this.DBitems.title_ar;
        }
      })
      .then((this.loading = false));
  },
  updated() {}
};
</script>

