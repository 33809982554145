<template>
    <div>
      
      <section  v-if="!registerUserInfo">
    <!-- START SECTION BREADCRUMB -->
    <div class="breadcrumb_section bg_gray page-title-mini">
      <div class="container"><!-- STRART CONTAINER -->
          <div class="row align-items-center">
              <div class="col-md-6">
                  <div class="page-title">
                      <h1>{{ $t("CreateAccount")  }}</h1>
                  </div>
              </div>
              <div class="col-md-6">
                  <ol class="breadcrumb justify-content-md-end">
                      <li class="breadcrumb-item">
                          <router-link to="/">{{ $t('home') }}
                              </router-link></li>
                      <li class="breadcrumb-item active">{{$t("CreateAccount") }}</li>
                  </ol>
              </div>
          </div>
      </div><!-- END CONTAINER-->
  </div>
  <!-- END SECTION BREADCRUMB -->
        <div>
          <p>{{ $t("EnterCode") }}</p>
          <div dir="ltr" class="center-text text-center">
            <CodeInput
              :loading="false"
              required
              class="input code"
              @change="onChange"
              @complete="onComplete"
              :fields="4"
            />
          </div>
          <p
            style="margin-top: 15px; margin-bottom: 0"
            v-if="$i18n.locale == 'en'"
          >
            {{ countDown }} second
          </p>
          <p style="margin-top: 15px; margin-bottom: 0" v-else>
            {{ countDown }} ثانية
          </p>
          <div
            v-if="validateMessage != ''"
            style="text-align: center; margin-top: 10px; margin-bottom: 0"
          >
            <span style="color: red; margin: 0">{{ validateMessage }}</span>
          </div>
          <div
            v-if="validateMessage1 != ''"
            style="text-align: center; margin-top: 10px; margin-bottom: 0"
          >
            <span style="color: var(--primary-color); margin: 0">{{
              validateMessage1
            }}</span>
          </div>
          <div class="text-center w-100 mt-3">
            <button v-if="countDown==0" class="ResendCode" @click="ResendCode()" style="margin: 20px">
              {{ $t("ResendCode") }}
            </button>
            <button @click="checkCode" style="z-index:0px !important" class="button" :disabled="countDown==0">
              {{ $t("continue") }}
            </button>
           
           <p v-if="this.$store.state.auth==0" class="alert_msg" style="color:red">
              {{ $t('code will be send to your whatsapp') }}</p>
          </div>
         
        </div>
        
      </section>
      
    </div>
  </template>
  <script>
  import CodeInput from "vue-verification-code-input";
  
  export default {
    components: { CodeInput},
    props: {
      phone: String,
      email: String,
      from1: Object,
      package_id: Number,
    },
    data: function () {
      return {
        registerUserInfo: false,
        checkcomplete: false,
        validateMessage: "",
        validateMessage1: "",
        finalcode: null,
        countDown: 90,
      };
    },
    created() {
      this.countDownTimer();
      console.log('package_id',this.package_id)

    },
    methods: {
      checkCode() {
        console.log('phone',this.phone);
        if (this.checkcomplete == true) {
          {
          this.axios
            .post(
                "https://dekakeen.com/api/website/verivey_codev1",
                {
                  
                  phone: this.phone,
                  code: this.finalcode,
                  fcm_token: this.$store.state.fsm_token
                },
              {
                headers: {
                  "Accept-Language": this.$i18n.locale,
                },
              }
            )
            .then((response) => {
              if (response.data.code == "-1") {
                //  console.log( response.data.code);
                this.validateMessage = response.data.message;
                setTimeout(() => {
                  this.validateMessage = "";
                }, 3000);
              } else if (response.data.code == "1") {
                this.registerUserInfo = true;
                

                if(this.$i18n.locale == 'en')
                {
                this.$router.push({
                  name: "invoice_en",
                  params: {
                    phone: this.phone,
                    package_id: this.package_id
                  }
                });
              }

              else
              {
                this.$router.push({
                  name: "invoice",
                  params: {
                    phone: this.phone,
                    package_id: this.package_id
                  }
                });
              }
             
              }
            });
          }
          
        } else {
          if (this.$i18n.locale == "en") {
            this.validateMessage = "your code isn't complete";
            setTimeout(() => {
              this.validateMessage = "";
            }, 3000);
          } else {
            this.validateMessage = "يرجى إكمال كافة الحقول";
            setTimeout(() => {
              this.validateMessage = "";
            }, 3000);
          }
        }
      },
      ResendCode() {
         {
        this.axios
          .post(
            "https://dekakeen.com/api/website/verivey_codev1",
            {
              phone: this.phone,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            if (response.data.code == "-1") {
              this.validateMessage = response.data.message;
              setTimeout(() => {
                this.validateMessage = "";
              }, 3000);
            } else if (response.data.code == "1") {
              this.countDown = 90;
              this.countDownTimer();
              this.validateMessage1 = response.data.message;
              setTimeout(() => {
                this.validateMessage1 = "";
              }, 3000);
            }
          });
        }
       
      },
      countDownTimer() {
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1;
            this.countDownTimer();
          }, 1000);
        }
      },
      onChange(v) {
        //console.log("onChange ", this.phone);
        this.checkcomplete = false;
      },
      onComplete(v) {
        console.log("onComplete ", v);
        this.finalcode = v;
        this.checkcomplete = true;
      },
    },
  };
  </script>
  
  <style scoped>
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #Second {
    padding-top: 150px;
    min-height: 700px;
  }
  .input.react-code-input-container
  {
    width:100% !important;
  }
  
  h3 {
    font-size: 20px;
    font-family: "Araboto-Bold";
    color: var(--primary-color);
    text-decoration: underline;
    text-underline-offset: 10px;
  }
  h4 {
    font-size: 18px;
    font-family: "Araboto-Medium";
    color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  p {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 15px;
    font-family: "Araboto-Medium";
    color: var(--secondary-color);
    text-align: center;
  }
  .button {
    background-color: var(--primary-color);
    border-radius: 10px;
    width: 30%;
    margin-left: 35%;
    margin-right: 35%;
    padding: 10px 0 15px;
    text-align: center;
    border: 2px solid var(--primary-color);
    color: #fff;
    font-size: 16px;
    font-family: "Araboto-Medium";
    /*margin-top: 40px;*/
  }
  
  .button:hover {
    background-color: #fff;
    color: #000;
  }
  
  .ResendCode {
    background-color: transparent;
    border: 0;
    color: var(--primary-color);
    text-decoration: 1px underline;
    text-underline-offset: 6px;
  }
  .ResendCode:hover {
    text-underline-offset: 10px;
  }
  /*@media (min-width: 1400px){
  .container {
      max-width: 1200px !important;
  }
  }*/
  @media (max-width: 992px) {
    h4 {
      font-size: 15px;
    }
    .card-img-actions .preview-1 {
      margin-top: 30px;
      width: 120px !important;
      height: 120px !important;
    }
    .card-img-actions {
      margin-top: 40px;
    }
    .button {
      width: 50%;
      margin-left: 25%;
      margin-right: 25%;
    }
  }
  @media (max-width: 772px) {
    .button {
      width: 60%;
      margin-left: 20%;
      margin-right: 20%;
    }
    p {
      font-size: 12px;
    }
    .alert_msg
    {
      color:red !important;
    }
  }
  </style>
  