<template>
  <div>
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <!-- ======= Second Section ======= -->
    <section style="margin-top:0px">
      <div class="container">
          <div data-aos="fade-up" data-aos-duration="1000" class="row" style="margin-top:50px ;">
            <h4>{{ this.DBitems.sub_title }}</h4>
            <h2>{{ this.DBitems.title }}</h2>
           
          </div>
          <div class="row mt-3">
          <div class="col-lg-3 col-md-4 col-6 " v-for="(item,index) in DBitems.services" :key="index"  data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200" >
            <div class="card">
              <div class="container">
                <img :src="item.icon" :alt="item.title" />
                <h4>{{ item.title }}</h4>
                <p>{{ item.description }}</p>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>
    <!-- End Second -->
  </div>
</template>
<script>

export default {
  /*  components: {
    card1,
  },*/
  data() {
    return {
      loading : false,
      DBitems: null
    };
  },
  created() {
    this.loading = true;
      this.axios
        .get("https://dekakeen.com/api/website/services", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          if (response.data.code == "1") {
            this.DBitems = response.data.data;
          }
        })
        .then(() => (this.loading = false));
  },
  methods: {
    updatedata() {
      this.loading = true;
      this.axios
        .get("https://dekakeen.com/api/website/services", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          if (response.data.code == "1") {
            this.DBitems = response.data.data;
          }
        })
        .then(() => (this.loading = false));
      //this.$store.state.langchange1 = false;
    }
  }
};
</script>
<style scoped>
section {
  padding-top: 0px;
  padding-bottom: 80px;
  color: var(--primary-color);
  font-family: "GraphikArabic-Medium";
  text-align: center;
}

section h1 ,section h1 {
  font-family: "GraphikArabic-Bold";
  font-size: 2em;
  line-height: 50px;
  background: linear-gradient(
    45deg,
    var(--primary-color),
  #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
section h4 {
  /*  width: 80%;*/
  font-family: "GraphikArabic-Medium";
  font-size: 1.3em;
  line-height: 50px;
}
section .card h4 {
  height: 70px;
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
}
 [dir='ltr'] section .card h4{
    font-size: 15px;
}
section p {
  /*  width: 80%;*/
  font-family: "GraphikArabic-Light";
  font-size: 11px;
  line-height: 15px;
  font-weight: 700;
}
.card {
  width: 100%;
  height: 90%;
  border: 0;
  border-radius: 25px;
  padding: 40px 0 20px 0;
  margin-top: 20px;
}

img {
  width: 50px;
  margin-bottom: 20px;
}

@media (max-width: 575px) {
    section {
    padding-top: 0px;
    padding-bottom: 20px;
  }
  section h2 {
    font-family: "GraphikArabic-Bold";
    font-size: 15px;
    line-height: initial
  }


  section h1 {
    font-family: "GraphikArabic-Bold";
    font-size: 15px;
    line-height: initial
  }
  section h4 {
    font-size: 12px;
    line-height: 30px;
  }
  .row {
    width: 96%;
    margin: auto;
  }
  
section .card h4 {
  height: 35px;
  font-size: 10px;
  line-height: 20px;
  font-weight: 600;
}
section p {
  font-size: 10px;
}
.card {
  width: 100%;
  height: 90%;
  border: 0;
  border-radius: 25px;
  padding: 20px 0 10px 0;
  margin-top: 15px;
}

img {
  width: 30px;
  margin-bottom: 15px;
}
}

</style>
