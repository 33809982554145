<template>
  <div>
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <!-- ======= Second Section ======= -->
    <section style="margin-top:0px">
      <div class="container">
        <div style="margin-top:50px ;">
          <h4>{{ $t('SeventhTitle') }}</h4>
          <h1>{{ $t('SeventhDescription') }}</h1>
          <h1>{{ $t('SeventhSubDescription') }}</h1>
        </div>
        
        <div style="min-height:800px">
       
        <div v-if="!monthly && !loading" class="row mt-5">
          <div class="col-lg-4" style="min-height:800px" v-for="(item,index) in DBitemsYearly" :key="index">
            <price-card v-if="item.is_feautred=='0'"
              :id="item.id"
              :name="item.name"
              :sub_name="item.sub_name"
              :description="item.description"
              :features="item.options"
              :price="item.cost"
              :period="$t('Paid annually')"
            ></price-card><!--:period="item.perioad + $t('year')"-->
            <price-card2 v-else
              :id="item.id"
              :name="item.name"
              :sub_name="item.sub_name"
              :description="item.description"
              :features="item.options"
              :price="item.cost"
              :period="$t('Paid annually')"
              
            ></price-card2>
          </div>
        </div>
        </div>
      </div>
    </section>
    <!-- End Second -->
  </div>
</template>
<script>
import priceCard from "./priceCard.vue";
import priceCard2 from "./priceCardStyle2.vue";

export default {
  components: {
    priceCard,
    priceCard2
  },
  data() {
    return {
      loading: false,
      DBitemsMonthly: null,
      DBitemsYearly: null,

      monthly: false,
      features: Array(
        "3 جيجابايت مساتحة تخزبن",
        "دعم فني",
        "ربط النطاق الخاص بك",
        "لغة أساسية + لغة إضافية"
      )
    };
  },
  created() {
    if (this.monthly) {
        this.loading = true;
        this.axios
          .get("https://dekakeen.com/api/website/monthly_packages", {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          })
          .then(response => {
            if (response.data.code == "1") {
              this.DBitemsMonthly = response.data.data.packages;
            }
          })
          .then(() => (this.loading = false));
      } else {
        this.loading = true;
        this.axios
          .get("https://dekakeen.com/api/website/yearly_packages", {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          })
          .then(response => {
            if (response.data.code == "1") {
              this.DBitemsYearly = response.data.data.packages;
            }
          })
          .then(() => (this.loading = false));
      }
  },
  watch:{
    'monthly'(){
       this.updatedata()
    }
  },
  methods: {
    updatedata() {
      if (this.monthly) {
        this.loading = true;
        this.axios
          .get("https://dekakeen.com/api/website/monthly_packages", {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          })
          .then(response => {
            if (response.data.code == "1") {
              this.DBitemsMonthly = response.data.data.packages;
            }
          })
          .then(() => (this.loading = false));
      } else {
        this.loading = true;
        this.axios
          .get("https://dekakeen.com/api/website/yearly_packages", {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          })
          .then(response => {
            if (response.data.code == "1") {
              this.DBitemsYearly = response.data.data.packages;
            }
          })
          .then(() => (this.loading = false));
      }
      //this.$store.state.langchange1 = false;
    }
  }
};
</script>
<style scoped>
section {
  padding-top: 20px;
  padding-bottom: 80px;
  color: var(--primary-color);
  font-family: "GraphikArabic-Medium";
  text-align: center;

  background-color: #fdfefe;
  border-top-left-radius: 50% 80px;
  border-top-right-radius: 50% 80px;
  margin-top: 0px;
}
section h2 {
  font-family: "GraphikArabic-Bold";
  font-size: 2em;
  line-height: 40px;
  background: linear-gradient(
    45deg,
    var(--primary-color),
    #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}


section h1 {
  font-family: "GraphikArabic-Bold";
  font-size: 2em;
  line-height: 40px;
  background: linear-gradient(
    45deg,
    var(--primary-color),
    #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
section h4 {
  /*  width: 80%;*/
  font-family: "GraphikArabic-Medium";
  font-size: 1.3em;
  line-height: 40px;
}
section .card h4 {
  height: 70px;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}
section p {
  /*  width: 80%;*/
  font-family: "GraphikArabic-Light";
  font-size: 11px;
  line-height: 15px;
  font-weight: 700;
}
.card {
  width: 100%;
  height: 90%;
  border: 0;
  border-radius: 25px;
  padding: 40px 0 20px 0;
  margin-top: 20px;
}
.btn {
  /*border: 2px solid var(--primary-color);*/
  background-color: var(--third-color);
  border-radius: 30px;
  padding: 10px 8px 10px 30px;
  font-size: 18px;
  font-family: "GraphikArabic-Medium";
  color: var(--primary-color);
  font-weight: 700;
  margin-top: 30px;
  text-decoration: none;
  z-index: 200;
  animation: animateBtn 0.7s alternate infinite;
}
.btn:hover {
  transform: scale(1.05);
  background-color: var(--third-color);
}
[dir="rtl"] .btn {
  padding: 10px 30px 10px 8px;
}
.btn i {
  font-size: 25px;
  background-color: #ffe9c8;
  border-radius: 50px;
  padding: 4px 14px;
  margin: 0 0 0 10px;
}
[dir="rtl"] .btn i {
  margin: 0 10px 0 0;
}
img {
  width: 50px;
  margin-bottom: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fcdc00;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

[dir="rtl"] .slider:before {
  left: 30px;
}

input:checked + .slider {
  background-color: #6001d3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6001d3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

[dir="rtl"] input:checked + .slider:before {
  -webkit-transform: translateX(-26px);
  -ms-transform: translateX(-26px);
  transform: translateX(-26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.tap-container {
  width: 30%;
  margin: 20px 35%;
}

.tap-container p {
  background-color: #fcdc00;
  border-radius: 25px;
  font-size: 15px;
  padding: 10px 20px;
}
@media (max-width: 992px) {
  .tap-container {
    width: 60%;
    margin: 20px 20% 0;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 630px) {
}

@media (max-width: 575px) {
  section {
    padding-top: 0px;
    padding-bottom: 20px;
    margin-top: 20px !important;
    border-top-left-radius: 50px 50px;
    border-top-right-radius: 50px 50px;
  }
  section h2 {
    font-family: "GraphikArabic-Bold";
    font-size: 15px;
    line-height: 20px;
  }


  section h1 {
    font-family: "GraphikArabic-Bold";
    font-size: 15px;
    line-height: 20px;
  }
  section h4 {
    font-size: 12px;
    line-height: 25px;
  }
  .row {
    width: 96%;
    margin: 10px auto !important;
  }
  .tap-container {
    width: 60%;
    margin: 20px 20% 0;
  }
  .tap-container p {
    background-color: #fcdc00;
    border-radius: 25px;
    font-size: 10px;
    padding: 5px 5px;
  }
  section .tap-container h4 {
    font-size: 10px;
  }

  .switch {
    width: 40px;
    height: 24px;
  }

  .slider:before {
    height: 16px;
    width: 16px;
    left: 4px;
  }

  [dir="rtl"] .slider:before {
    left: 20px;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  [dir="rtl"] input:checked + .slider:before {
    -webkit-transform: translateX(-16px);
    -ms-transform: translateX(-16px);
    transform: translateX(-16px);
  }
}
li.trueMark {
    margin: 1px 0px;
}

@media (max-width: 400px) {
}
</style>
