<template>
  <section class="single_blog ">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <div class="title center-text text-center">
  <h1 class="blog_title">{{this.DBitems.name}}</h1>
  
    <img :src="this.DBitems.image" class="blog_img"
     loading="lazy" 
    :alt="this.DBitems.name"
     width="1200"
    height="400"
    />
    <div class="meta_single_blog">
   <label class=" meta_blog category"> <i class="bi bi-bookmarks-fill"></i> {{ this.DBitems.category }}</label>
   <label class="meta_blog date"><i class="bi bi-calendar"></i> {{ this.DBitems.created_at }}</label>
   <label class="meta_blog view"><i class="bi bi-eye"></i> {{ this.DBitems.view }}</label>

    </div>
    </div>
   

      <!--<div class="container" v-html="DBitems.title"></div>-->
      <div v-if="this.DBitems" class="container" v-html="this.DBitems.description">
        <!--v-html=""-->
      </div>
   
  </section>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      loading: false,
      DBitems: null
    };
  },
  created() {

    this.loading = true;
    this.axios
      .get("https://dekakeen.com/api/website/blog/"+this.$route.params.id, {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => {
        this.DBitems = response.data.data;
        
        if(this.DBitems.seo_description)
        document.getElementById("description").content=
        this.DBitems.seo_description;
        if(this.DBitems.seo_keyword)
        document.getElementById("keyword_meta").content=this.DBitems.seo_keyword;
        if(this.DBitems.seo_title)
        document.title = this.DBitems.seo_title;

      })
      .then(() => (this.loading = false));

  },
  methods: {
    updatedata() {
    this.loading = true;
    this.axios
      .get("https://dekakeen.com/api/website/blog/"+this.$route.params.id, {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => {
        this.DBitems = response.data.data;
        
        if(this.DBitems.seo_description)
        document.getElementById("description").content=
        this.DBitems.seo_description;
        if(this.DBitems.seo_keyword)
        document.getElementById("keyword_meta").content=this.DBitems.seo_keyword;
        if(this.DBitems.seo_title)
        document.title = this.DBitems.seo_title;

      })
      .then(() => (this.loading = false));

  }
  }
};
</script>

<style scoped>
.single_blog {
  padding-top: 150px;
  min-height: 600px;
}
.blog_img
{
  aspect-ratio: 3/1;
  object-fit: cover;
  max-height:400px;
}
@media (max-width: 992px) {
  .single_blog {
  padding-top: 85px;
  min-height: 600px;
}
}
section .blog_title{
  font-family: "GraphikArabic-Bold";
  font-size: 2em;
  line-height: 50px;
  background: linear-gradient(
    45deg,
    var(--primary-color),
    #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  margin-top:2%;
  margin-bottom: 2%;
}
.title
{
}
.meta_blog
{
  margin:2% 5%;
  background: var(--primary-color);
    color: white;
    padding: 4px 15px;
    border-radius: 12px;
    text-align: center;
    min-width:150px
}
</style>
