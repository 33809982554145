<template>
  <div>
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <!-- ======= Second Section ======= -->
    <section style="margin-top:0px">
      <div class="container">
        <div class="row" style=" margin-top:50px">
          <div class="col-lg-5">
            <div class="ThirdTitle" >
              <h1 data-aos="fade-zoom-in" data-aos-duration="1000">{{this.DBitems.title }}</h1>
              <h4 data-aos="fade-zoom-in" data-aos-duration="1000">{{this.DBitems.description }}</h4>
              
               <router-link class="btn" dir="auto" 
               v-if="$i18n.locale == 'en'"
                          :to="{
                              name: 'loginphone_en',
                            }">
                            {{ $t("CreateStore") }}
                            <i
                              :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
                            ></i>
                          
                          </router-link>


                          <router-link class="btn" dir="auto" 
                         v-else
                          :to="{
                              name: 'loginphone',
                            }">
                            
                            {{ $t("CreateStore") }}
                            <i
                              :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
                            ></i>
                        
                          </router-link>
            </div>
          </div>
          <div class="col-lg-7" style="margin-top:20px">
            <div class="row">
              <div class="col-6">
                <div data-aos="flip-up" data-aos-duration="1000">
                  <img @click="toggleDialog(this.DBitems.images[0])"
                    style="width:100%"
                    :src=this.DBitems.images[0]
                    alt="Statistics , Dekakeen Platform,e-commerce"
                    loading="lazy"
                    width="100%"
                    height="100%"
                  />
                 
                </div>
                <div data-aos="flip-up" data-aos-duration="1000">
                  <img @click="toggleDialog(this.DBitems.images[1])"
                 
                    style="width:100%;margin-top:5%; "
                    :src=this.DBitems.images[1]
                    alt="Statistics , Dekakeen Platform,e-commerce"
                    loading="lazy"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
              <div class="col-6" data-aos="flip-up" data-aos-duration="1000">
                <img @click="toggleDialog(this.DBitems.images[2])"
                  style="height:70%"
                  
                  :src=this.DBitems.images[2]

                  alt="Statistics , Dekakeen Platform,e-commerce"
                  loading="lazy"
                    width="100%"
                    height="100%"
                />
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="dialog" v-if="dialog">
      <div class="dialog-content">
        <button @click="toggleDialog('')" class="close-icon">x</button>
        <img :src="imageSrc" />
      </div>
    </div>
    <!-- End Second -->
  </div>
</template>
<script>

export default {

  data() {
    return {
      imageSrc:
        "https://www.sarkarinaukriexams.com/images/editor/1670265927Capture123243243423.PNG",
      dialog: false,
      DBitems:null,
    };
  },
  created() {
    this.loading = true;
      this.axios
        .get("https://dekakeen.com/api/website/third_section", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          if (response.data.code == "1") {
            this.DBitems = response.data.data;
          }
        })
        .then(() => (this.loading = false));
  },
  methods: {
    toggleDialog(imagePath) {
      this.imageSrc= imagePath;
      this.dialog = !this.dialog;
    },
    async updatedata() {
      this.loading = true;
      this.axios
        .get("https://dekakeen.com/api/website/third_section", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          if (response.data.code == "1") {
            this.DBitems = response.data.data;
          }
        })
        .then(() => (this.loading = false));
    }
    
  }
};
</script>
<style scoped>
section {
  padding-top: 20px;
  padding-bottom: 50px;
  color: var(--primary-color);
  font-family: "GraphikArabic-Medium";
  min-height: 200px;
}
section h1 ,section h2{
  font-family: "GraphikArabic-Bold";
  font-size: 2em;
  line-height: 50px;
  background: linear-gradient(
    45deg,
    var(--primary-color),
    #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
section h4 {
  /*  width: 80%;*/
  font-family: "GraphikArabic-Medium";
  font-size: 1.3em;
  line-height: 35px;
}
.btn {
  /*border: 2px solid var(--primary-color);*/
  background-color: var(--third-color);
  border-radius: 30px;
  padding: 10px 8px 10px 30px;
  font-size: 18px;
  font-family: "GraphikArabic-Medium";
  color: var(--primary-color);
  font-weight: 700;
  margin-top: 30px;
  text-decoration: none;
  z-index: 200;
  animation: animateBtn 0.7s alternate infinite;
}
.btn:hover {
  transform: scale(1.05);
  background-color: var(--third-color);
}
[dir="rtl"] .btn {
  padding: 10px 30px 10px 8px;
}
.btn i {
  font-size: 25px;
  background-color: #ffe9c8;
  border-radius: 50px;
  padding: 4px 14px;
  margin: 0 0 0 10px;
}
[dir="rtl"] .btn i {
  margin: 0 10px 0 0;
}
img {
  width: 100%;
  border-radius: 15px;
  cursor: zoom-in;
  /*box-shadow: 0 0 10px #a1a1a1;*/
}

.dialog {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1000;
  margin: 0 auto;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #999;
}
.dialog-content {
  max-width: 50%;
  height: 600px;
  overflow: auto;
  margin: 0 auto;
  padding: 50px 10px;
  margin-top: 20px;
}
.dialog-content img {
  width: 100%;
  cursor: default;
}
.close-icon {
  float: right;
  background-color: transparent;
  border:0;
  font-size: 25px;
  color: #fff;
}
@media (min-width: 1400px) {
  .ThirdTitle {
    margin-top: 40px ;
  }
}
@media (max-width: 992px) {
}

@media (max-width: 1200px) {
}

@media (max-width: 630px) {
  .dialog-content {
  max-width: 90%;
 
}
}

@media (max-width: 575px) {
  section {
    padding-top: 20px;
    padding-bottom: 0px;
  }
  section h2 {
    font-family: "GraphikArabic-Bold";
    font-size: 15px;
    line-height: 20px;
  }

  section h1 {
    font-family: "GraphikArabic-Bold";
    font-size: 15px;
    line-height: 20px;
  }

  section h4 {
    font-size: 12px;
    line-height: 25px;
  }
  .ThirdTitle {
    margin-top: 10px !important;
  }
  .btn {
    display: none;
  }
  .row {
    width: 96%;
    margin: auto;
    margin-top: 5px !important;
  }
}

@media (max-width: 400px) {
}
</style>
