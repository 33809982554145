<template>
  <div>
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <footer id="footer">
      <!--<div class="footer-image mobile-inactive"></div>-->
      <div v-if="!loading" class="container mt-3">
        <div class="row footer-width" style="width: 100%">
          <div class="col-lg-4 mb-3">
            <div class="logo d-flex flex-row">
              <a href="/">
                <img
                  src="https://dekakeen.com/websiteImages/logoFooter.png"
                 
                  class="img-fluid"
                  style="width:100%"
                  loading="lazy"
                 alt="Dekakeen"
                 width="100%"
                 height="100%"

                />
              </a>
            </div>
            <div class="description_footer">
              <p>
                {{ $t('description_footer') }}
              </p>

            </div>
            <div v-if="DBitems.google_play_link ||DBitems.apple_store_link">
              <h2 class="mb-4">{{ $t("Download") }}</h2>
              <div class="DownloadDiv">
                <a v-if="DBitems.google_play_link" :href="DBitems.google_play_link">
                  <img
                    src="https://dekakeen.com/websiteImages/Google play.png"
                  
                    class="DownloadImage img-fluid"
                    style="width:120px"

                 loading="lazy"
                 alt="Dekakeen Google Play"
                 width="120"
                 height="100%"
                  />
                </a>
                <a v-if="DBitems.apple_store_link" :href="DBitems.apple_store_link">
                  <img
                    src="https://dekakeen.com/websiteImages/App Store.png"
                   
                    class="DownloadImage img-fluid"
                    style="width: 120px;margin-left: 10px; margin-right: 10px;"
                    loading="lazy"
                    alt="Dekakeen Apple Store"
                    width="120"
                    height="100%"
                  />
                </a>
              </div>
            </div>

            <div class="mt-5 social-linkes">
              <a v-if="DBitems.tiktok" :href="DBitems.tiktok">
                <img src="https://dekakeen.com/websiteImages/tik-tok.png" 
                loading="lazy"
                 alt="Dekakeen ,TikTok"
                 width="100%"
                 height="100%"
                />
              </a>
              <a v-if="DBitems.youtube" :href="DBitems.youtube">
                <img src="https://dekakeen.com/websiteImages/youtube.png"
                loading="lazy"
                 alt="Dekakeen ,YouTube"
                 width="100%"
                 height="100%"
                 />
              </a>
              <a v-if="DBitems.instgram" :href="DBitems.instgram">
                <img  src="https://dekakeen.com/websiteImages/instagram.svg" 
                
                loading="lazy"
                 alt="Dekakeen ,Instgram"
                 width="100%"
                 height="100%"
                />
              
              </a>
              <a v-if="DBitems.facebook" :href="DBitems.facebook">
                <img  src="https://dekakeen.com/websiteImages/facebook.svg"
                loading="lazy"
                 alt="Dekakeen ,FaceBook"
                 width="100%"
                 height="100%"
                 />
              
              </a>
            </div>
          </div>
          <div class="container mobile-active">
            <hr />
          </div>
          <div class="col-lg-2 col-6 mb-3" style="margin-top: 30px">
            <!-- <div class="d-flex flex-row justify-content-around"> -->
            <div>
              <div>
                <h2 class="mb-4">{{ $t("about-us") }}</h2>
              
                <router-link
                 v-if="$i18n.locale == 'en'" 
                 :to="{
                name: 'home_en',
              }">
              <p>{{ $t("home") }}</p>
                </router-link>


                <router-link v-else :to="{
                name: 'home',
              }">
              <p>{{ $t("home") }}</p>
                </router-link>

                <router-link  v-if="$i18n.locale == 'en'" :to="{
                name: 'pricing_en',
              }">
              <p>{{ $t("pricing") }}</p>
                </router-link>



                <router-link  v-else :to="{
                name: 'pricing',
              }">
              <p>{{ $t("pricing") }}</p>
                </router-link>


                <router-link
                v-if="$i18n.locale == 'en'"
                  :to="{
                    name: 'privacy_en',
                    params: { storeName: this.$store.storeName },
                  }"
                >
                <p>{{ $t("privacyPolicy") }}</p>
                </router-link>




                <router-link
               v-else
                  :to="{
                    name: 'privacy',
                    params: { storeName: this.$store.storeName },
                  }"
                >
                <p>{{ $t("privacyPolicy") }}</p>
                </router-link>
                <router-link
                v-if="$i18n.locale == 'en'"
                  :to="{
                name: 'about_en',
                params: { storeName: this.$store.storeName },
              }"
                >

                <p>{{$t('HowWork')}}</p>
                </router-link>


                <router-link
               v-else
                  :to="{
                name: 'about',
                params: { storeName: this.$store.storeName },
              }"
                >
                
                <p>{{$t('HowWork')}}</p>
                </router-link>
                
                <router-link
                v-if="$i18n.locale == 'en'"
                  :to="{
                    name: 'questions_en',
                    params: { storeName: this.$store.storeName },
                  }"
                >
                <p>{{ $t("questions") }}</p>
                </router-link>



                <router-link
                v-else
                  :to="{
                    name: 'questions',
                    params: { storeName: this.$store.storeName },
                  }"
                >
                <p>{{ $t("questions") }}</p>
                </router-link>
                <router-link
                v-if="$i18n.locale == 'en'"
                  :to="{
                    name: 'Seller_Agreement_en',
                    params: { storeName: this.$store.storeName },
                  }"
                >
                <p>{{ $t("Seller Agreement") }}</p>
                </router-link>




                <router-link
               v-else
                  :to="{
                    name: 'Seller_Agreement',
                    params: { storeName: this.$store.storeName },
                  }"
                >
                <p>{{ $t("Seller Agreement") }}</p>
                </router-link>

                <router-link
                v-if="$i18n.locale == 'en'"
                  :to="{
                    name: 'terms_en',
                    params: { storeName: this.$store.storeName },
                  }"
                >
                <p>{{ $t("Terms and Conditions") }}</p>
                </router-link>



                <router-link
               v-else
                  :to="{
                    name: 'terms',
                    params: { storeName: this.$store.storeName },
                  }"
                >
                <p>{{ $t("Terms and Conditions") }}</p>
                </router-link>
              </div>
            </div>
          </div>
          <!--animate__animated animate__fadeInLeft-->
          <div class="col-lg-2 col-6 mb-3" style="margin-top: 30px">
            <!-- <div class="d-flex flex-row justify-content-around"> -->
            <div>
              <div class="contact-info">
                <h2 class="mb-4">{{ $t("contact") }}</h2>
                <h4 class="phoneNumber">
                  <p dir="ltr">{{ DBitems.phone }}</p>
                  <!--<p>{{ DBitems.social.phone }}</p>-->
                  <!--edittttttttttttt tow phone-->
                </h4>
                <h4 v-if="DBitems.other_phone!=null" class="phoneNumber">
                  <p dir="ltr">{{ DBitems.other_phone }}</p>
                  <!--<p>{{ DBitems.social.phone }}</p>-->
                  <!--edittttttttttttt tow phone-->
                </h4>
                <h4 v-if="DBitems.whatsapp!=null" class="phoneNumber">
                  <p dir="ltr">whatsapp : {{ DBitems.whatsapp }}</p>
                  <!--<p>{{ DBitems.social.phone }}</p>-->
                  <!--edittttttttttttt tow phone-->
                </h4>
                <a :href="'mailto:' + item" v-for="(item, index) in DBitems.email" :key="index">
                  <p>{{item }}</p>
                </a>
                <!--<a :href="'https://'+ DBitems.website">
                  <p>{{ DBitems.website }}</p>
                </a>-->
                <h4 v-if="DBitems.mail_box">
                  <p>{{ $t("mail_box")  }} : {{   DBitems.mail_box  }}</p>
                </h4>
                <h4 v-if="!loading">
                  <p>{{ DBitems.address }}</p>
                  <!--<p>{{ DBitems.social.website }}</p>-->
                </h4>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-12 mb-3" style="margin-top: 15px">
            <contact-card></contact-card>
          </div>
         
          <div class="col-lg-12 col-12 ">
            <div class="copyRight text-center" style="margin-top: 30px">
              <p dir="ltr">©2024 All rights reserved to AL BAB AL TAQNI FOR IT.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import contactCard from "./contactCard.vue";

export default {
  
  name: "Footer",
  components: { contactCard },
  props: {
    // msg: String,
  },
  data: function() {
    return {
      logo: "",
      DBitems: null,
      loading: false
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get("https://dekakeen.com/api/website/social_media_info", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => {
        if (response.data.code == "1") {
          this.DBitems = response.data.data.social;
        }
      })
      .then(() => (this.loading = false));
  },
  methods: {
    async updatedata() {
      this.loading = true;
      await this.axios
        .get("https://dekakeen.com/api/website/social_media_info", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          if (response.data.code == "1") {
            this.DBitems = response.data.data.social;
          }
        })
        .then(() => (this.loading = false));
      this.$store.state.langchange = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.DownloadImage:hover {
  transform: scale(1.05);
}
[dir="rtl"] .phoneNumber,
[dir="ltr"] .copyRight {
  text-align: end;
}
.DownloadDiv a {
  cursor: pointer;
}
a,
a:focus{
  color: #fff !important;
}
a:hover{
  color: #ffdda9 !important;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
.description_footer
{
  width:90%;
}
</style>
