<template>
  <div style="min-height: 600px">
    <hero></hero>
    <third></third>
    <third_section2></third_section2>
    <fourth></fourth>
    <sixth-themes></sixth-themes>
    <fifth></fifth>
    <seventh></seventh>
  
  </div>
</template>
<script>
import hero from "../components/hero.vue";
import third from "../components/third.vue";
import third_section2 from "../components/third_section2.vue";
import fourth from "../components/fourth.vue";
import fifth from "../components/fifth.vue";
import sixthThemes from "../components/sixthThemes.vue";
import seventh from "../components/seventh.vue";




export default {
    components: {
    hero,
    third,
    third_section2,
    fourth,
    fifth,
    sixthThemes,
    seventh,
  
  },
  data() {
    return {};
  },
  created() {


  if(this.$i18n.locale=='ar')
  {
   document.getElementById("description").content=
   this.$store.state.description_ar;
    document.getElementById("keyword_meta").content=this.$store.state.keyword_ar;
    document.title = document.title = this.$store.state.title_ar;
   }
   else
   {
    document.getElementById("description").content=
    this.$store.state.description_en;
    document.getElementById("keyword_meta").content=this.DBitems.keyword_en;
    document.title = this.$store.state.title_en;
   }
  
  
  },
  methods: {

  }
};
</script>
<style scoped>

</style>
