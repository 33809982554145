<template>

  <section id="Pricing">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <pricing></pricing>
  </section>
</template>
<script>
import pricing from "../components/pricing.vue";

export default {
  components:{pricing},
  data: function() {
    return {
      loading: false,
      DBitems: null
    };
  },

  created() {
    this.loading = true;
    this.axios
      .get("https://dekakeen.com/api/website/page/Pricing", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => {
        this.DBitems = response.data.data.page;
      if(this.DBitems.seo_description)
        document.getElementById("description").content=
        this.DBitems.seo_description;
        if(this.DBitems.seo_keyword)
        document.getElementById("keyword_meta").content=this.DBitems.seo_keyword;
        if(this.DBitems.seo_title)
        document.title = this.DBitems.seo_title;
       //end seo


      })
      .then(() => (this.loading = false));
  },
  methods: {
    updatedata() {
      this.loading = true;
      this.axios
        .get("https://dekakeen.com/api/website/page/Pricing", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.DBitems = response.data.data.page;

          if(this.DBitems.seo_description)
        document.getElementById("description").content=
        this.DBitems.seo_description;
        if(this.DBitems.seo_keyword)
        document.getElementById("keyword_meta").content=this.DBitems.seo_keyword;
        if(this.DBitems.seo_title)
        document.title = this.DBitems.seo_title;
        })
        .then(() => (this.loading = false));
     
    }
  }
};


</script>

<style scoped>
#Pricing {
  padding-top: 90px;
  min-height: 850px;
  padding-bottom: 0;
   background-color:#fff
}

</style>
