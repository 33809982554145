var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_blog col-lg-4 col-sm-6"},[(_vm.$i18n.locale=='ar')?_c('router-link',{staticClass:"post-entry__thumb",attrs:{"to":{
      name: 'blog',
      params: { categoryName: this.category, id: this.id  , name:this.name},
          }}},[_c('img',{staticClass:"blog_gird_img",attrs:{"src":this.image,"loading":"lazy","width":"400","height":"200","alt":this.name}})]):_c('router-link',{staticClass:"post-entry__thumb",attrs:{"to":{
      name: 'blog_en',
      params: { categoryName: this.category, id: this.id  , name:this.name},
          }}},[_c('img',{attrs:{"src":this.image,"loading":"lazy","width":"400","height":"200","alt":this.name}})]),_c('div',{staticClass:"post-entry__meta"},[(_vm.$i18n.locale=='ar')?_c('router-link',{staticClass:"inline-block badge badge--xs mb-1 me-2 blog_category",attrs:{"to":{
      name: 'blogs_category',
      params: { categoryName: this.category, id:this.category_id},
          }}},[_vm._v(" "+_vm._s(this.category)+" ")]):_c('router-link',{staticClass:"inline-block badge badge--xs mb-1 me-2 blog_category",attrs:{"to":{
      name: 'blogs_category_en',
      params: { categoryName: this.category, id:this.category_id},
          }}},[_vm._v(" "+_vm._s(this.category)+" ")]),(_vm.$i18n.locale=='ar')?_c('router-link',{attrs:{"to":{
      name: 'blog',
      params: { categoryName: this.category, id: this.id  , name:this.name},
          }}},[_c('h4',{staticClass:"blog_title"},[_vm._v(_vm._s(this.name)+" ")])]):_c('router-link',{attrs:{"to":{
      name: 'blog_en',
      params: { categoryName: this.category, id: this.id  , name:this.name},
          }}},[_c('h4',{staticClass:"blog_title"},[_vm._v(_vm._s(this.name)+" ")])]),_c('time',{staticClass:"text-xs mt-2",attrs:{"datetime":this.date,"itemprop":"datePublished"}},[_vm._v(_vm._s(this.date))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }