<template>
  <div>
    <!-- ======= Second Section ======= -->
    <section style="margin-top:0px">
      <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>

      <div class="container">
        <div class="row">
          <div class="col-lg-6 colMobile" style="margin-top:20px">
            <div data-aos="fade-in"  data-aos-duration="2000" >
              <img 
                style="width:90%; margin-right:5%; margin-left:5%"
                :src=this.DBitems.images[0]
                loading="lazy"
                alt="Dekakeen,Statices "
                width="90%"
                height="600"
              />
             
            </div>
          </div>
          <div class="col-lg-6">
            <div data-aos="fade-in"  data-aos-duration="1000" data-aos-delay="100"  class="colMobile" style=" margin-top:60px">
              <h1>{{ this.DBitems.title }}</h1>
              <h4 class="FifthDescription">{{ this.DBitems.description }}</h4>
              <div class="row">
                <div class="col-lg-6">
               <router-link class="btn" dir="auto" 
               v-if="$i18n.locale == 'en'"
                          :to="{
                              name: 'loginphone_en',
                            }">
                            {{ $t("CreateStore") }}
                            <i
                              :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
                            ></i>
                           
                          </router-link>


                          <router-link class="btn " dir="auto" 
              v-else
                          :to="{
                              name: 'loginphone',
                            }">
                            {{ $t("CreateStore") }}
                            <i
                              :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
                            ></i>
                            
                          </router-link>
                               </div>
                               <div class="col-lg-6">

                          <a href="https://dekakeen.com/dashboard/demo/trial" class="btn demo_btn" dir="auto" 
               v-if="$i18n.locale == 'en'"
               style=" padding: 10px 10px 10px 8px !important;"
                         >
                            {{ $t("demo_test") }}
                            <i
                              :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
                            ></i>
                           
                          </a>


                          <a href="https://dekakeen.com/dashboard/demo/trial" class="btn demo_btn" dir="auto" 
              v-else
              style="padding: 10px 10px 10px 8px !important;"
                          >
                            {{ $t("demo_test") }}
                            <i
                              :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
                            ></i>
                            
                        </a>

                          </div>
                          </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Second -->
  </div>
</template>
<script>
//import card1 from "../components/CardStyle1.vue";

export default {

  data() {
    return {
      DBitems:null,
    };
  },
  created() {
    this.loading = true;
      this.axios
        .get("https://dekakeen.com/api/website/fourth_section", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          if (response.data.code == "1") {
            this.DBitems = response.data.data;
          }
        })
        .then(() => (this.loading = false));
  },
  methods: {
    async updatedata() {
      this.loading = true;
      this.axios
        .get("https://dekakeen.com/api/website/fourth_section", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          if (response.data.code == "1") {
            this.DBitems = response.data.data;
          }
        })
        .then(() => (this.loading = false));
    }
  }
};
</script>
<style scoped>
section {
  padding-top: 20px;
  padding-bottom: 80px;
  color: var(--primary-color);
  font-family: "GraphikArabic-Medium";
  min-height: 200px;
}

section h1,section h2 {
  font-family: "GraphikArabic-Bold";
  font-size: 2em;
  width: 70%;
  line-height: 50px;
  background: linear-gradient(
    45deg,
    var(--primary-color),
    #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
section h4 {
  /*  width: 80%;*/
  font-family: "GraphikArabic-Medium";
  font-size: 1.3em;
  line-height: 40px;
}
.btn {
  /*border: 2px solid var(--primary-color);*/
  background-color: var(--third-color);
  border-radius: 30px;
  padding: 10px 8px 10px 30px;
  font-size: 18px;
  font-family: "GraphikArabic-Medium";
  color: var(--primary-color);
  font-weight: 700;
  margin-top: 30px;
  text-decoration: none;
  z-index: 200;
  animation: animateBtn 0.7s alternate infinite;
}
.btn:hover {
  transform: scale(1.05);
  background-color: var(--third-color);
}
[dir="rtl"] .btn {
  padding: 10px 30px 10px 8px;
}
.btn i {
  font-size: 25px;
  background-color: #ffe9c8;
  border-radius: 50px;
  padding: 4px 14px;
  margin: 0 0 0 10px;
}
[dir="rtl"] .btn i {
  margin: 0 10px 0 0;
}
img {
  width: 100%;
  border-radius: 15px;
}
@media (min-width: 1400px) {
  section h4[data-v-0582be57] {
    line-height: 50px;
    width: 85%;
  }
}

@media (max-width: 992px) {
   .row {
    flex-direction: column-reverse;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 630px) {
}

@media (max-width: 575px) {
  section {
    padding-top: 0px;
    padding-bottom: 20px;
  }
  section h2 {
    font-family: "GraphikArabic-Bold";
    font-size: 15px;
    line-height: 20px;
  }

  section h1 {
    font-family: "GraphikArabic-Bold";
    font-size: 15px;
    line-height: 20px;
  }
  section h4 {
    font-size: 12px;
    line-height: 25px;
  }
  .row {
    width: 96%;
    margin: auto;
    flex-direction: column-reverse;
  }
  .colMobile {
    margin-top: 20px !important;
  }

  .btn {
    padding: 10px 8px 10px 30px;
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
  }
  .btn i {
    font-size: 15px;
    padding: 4px 10px;
  }
  .cd-headline b {
    line-height: 50px;
  }

  .btn_demo {
    padding: 10px 10px 10px 8px !important;
}
}


</style>
