<template>
    <div class="grid_blogs">
      <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
      <section  v-if="!loading">
        <h1>{{$t('blogs')}}/{{ this.$route.params.categoryName }}</h1>
        <div class="container">
          <div class="row">
         <blog_card  v-for="(item, index) in this.DBitems"
            :key="index"
            :id= item.id
            :name= item.name
            :description= item.description
            :date= item.created_at
            :category= item.category
            :image= item.image
            :category_id= item.category_id
            >
          </blog_card>
        </div>
        </div>
        <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                :prev-text="$t('prev')"
                :next-text="$t('next')"
                pills
                size="sm"
                class="pagination mt-3 justify-content-center pagination_style1"
                @change="handlePageChange"
              ></b-pagination>
      </section>
    </div>
  </template>
  <script>
  import blog_card from "../components/blogCard.vue";

  export default {
    components: {blog_card},
    data: function() {
      return {
        loading: false,
        DBitems: null,
        page: 1,
        count: 0,
        pageSize: 12,
        langChange:"",
         paginate:12,
      };
    },
    created() {
      this.loading = true;
      this.axios
        .get("https://dekakeen.com/api/website/blogs/category/"+this.$route.params.id+"?page=" +
            this.page, {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.DBitems = response.data.data;
          this.count = response.data.meta.total;
       
  
  
        })
        .then(() => (this.loading = false));
    },
    methods: {
      updatedata() {
        this.loading = true;
      this.axios
        .get("https://dekakeen.com/api/website/blogs/category/"+this.$route.params.id+"?page=" +
            this.page, {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.DBitems = response.data.data;
          this.count = response.data.meta.total;
       
  
  
        })
        .then(() => (this.loading = false));
      },
      handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },

    retrieveTutorials()
    {
      this.loading = true;
      this.axios
        .get("https://dekakeen.com/api/website/blogs/category/"+this.$route.params.id+"?page=" +
            this.page, {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.DBitems = response.data.data;
          this.count = response.data.meta.total;
       
  
  
        })
        .then(() => (this.loading = false));
    }
    }
  };
  </script>
  
  <style scoped>
  .grid_blogs {
    padding-top: 150px;
    min-height: 600px;
  }
  @media (max-width: 992px) {
    .grid_blogs {
    padding-top: 85px;
    min-height: 600px;
  }
  }


  section h1 ,section h2{
  font-family: "GraphikArabic-Bold";
  font-size: 2em;
  line-height: 50px;
  background: linear-gradient(
    45deg,
    var(--primary-color),
    #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  text-align:center;
  margin-bottom:10px;
}
  </style>
  