<template>
  <header id="topheader" class="fixed-top d-flex align-items-center">
    <div v-if="!loading" class="container d-flex align-items-center justify-content-between">
      <div class="social-linkes">
        <a dir="ltr" :href="'tel:'+DBitems.phone" style="color:#fff">
          {{ DBitems.phone }}
          <img class="m-2" src="https://dekakeen.com/websiteImages/phone.png"
          alt="dekakeen ,Phone"
          loading="lazy"
          width="100%"
          height="100%"
           />
        </a>
      </div>
      <div class="social-linkes">
        <a v-if="DBitems.tiktok" :href="DBitems.tiktok">
          <img src="https://dekakeen.com/websiteImages/tik-tok.png"
          alt="dekakeen ,TikTok"
          loading="lazy"
          width="100%"
          height="100%"
           />
        </a>
        <a v-if="DBitems.youtube" :href="DBitems.youtube">
          <img src="https://dekakeen.com/websiteImages/youtube.png" 
          alt="dekakeen ,youtube"
          loading="lazy"
          width="100%"
          height="100%"
          />
        </a>
        <a  v-if="DBitems.instgram" :href="DBitems.instgram">
          <img src="https://dekakeen.com/websiteImages/instagram.svg"
          alt="dekakeen ,Instgram"
          loading="lazy"
          width="100%"
          height="100%"
           />
         
        </a>
        <a  v-if="DBitems.facebook" :href="DBitems.facebook">
          <img src="https://dekakeen.com/websiteImages/facebook.svg" 
          alt="dekakeen ,FaceBook"
          loading="lazy"
          width="100%"
          height="100%"
          />
          
        </a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
 
  name: "Header",
  props: {
  
    storeName: String
  },
  data: function() {
    return {
      logo: "",
      DBitems: null,
      loading: false
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get("https://dekakeen.com/api/website/social_media_info", {
        headers: {
          "Accept-Language": "en"
        }
      })
      .then(response => {
        if (response.data.code == "1") {
          this.DBitems = response.data.data.social;
        }
      })
      .then(() => (this.loading = false));
  },

  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#topheader {
  height: 35px;
  /* transition: all 0.5s; */
  z-index: 997;
  background-color: var(--primary-color);
}
[dir="rtl"] .bi-search {
  margin-right: 72%;
  margin-top: 6px;
  position: absolute;
}
[dir="ltr"] .bi-search {
  margin-left: 72%;
  margin-top: 6px;
  position: absolute;
}

.filterDropdown ul,
li {
  list-style: none;
}
.filterDropdown ul {
  display: block;
  position: absolute;
  left: 5px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 15px;
  min-height: 190px;
}

/*.navbar .dropdown ul li,*/

.filterDropdown ul li {
  min-width: 200px;
}

/*.navbar .dropdown ul a,*/




button {
  background-color: transparent;
  border: 0;
  /*border: 1px solid var(--primary-color);
  border-radius: 15px;
  padding: 10px;*/
}

.createAccount {
  background-color: var(--primary-color);
  border-radius: 25px;
  border: 2px solid var(--primary-color);
  cursor: pointer;
}
.createAccount a {
  color: #fff;
}
.createAccount:hover {
  background-color: #fff;
  border: 2px solid var(--primary-color);
}

.LangBtn {
  background-color: var(--third-color);
  border-radius: 25px;
  border: 2px solid var(--third-color);
  cursor: pointer;
  margin: auto 10px;
}
.LangBtn:hover {
 
  transform: scale(1.02);
}

a:hover {
  color: var(--third-color) !important;
}

.social-linkes img {
  width: 18px;
  height: 18px;
  margin: 10px;
}
.social-linkes img:hover {
  transform: scale(1.1);
}
@media (max-width: 1200px) and (min-width: 992px) {
  .navbar li {
    padding: 5px 7px 7px 7px;
  }
  .navbar a {
    padding: 5px 5px;
  }
 
}
@media (max-width: 575px) {
  .social-linkes img {
    width: 16px;
    height: 16px;
    margin: 5px !important;
  }
}
</style>
