<template>
  <hooper
    class="storeSlider"
    style="height: 100%; margin-top: 50px;padding-top: 0px;padding-bottom: 0px;"
    :rtl=" $i18n.locale == 'ar' ? true : false"
    :settings="hooperSettings"
  >
    <slide style="padding:5px" v-for="(item,index) in items" :key="index">
      <store-card
        :store_id= item.id
        :store_name= item.name
        :store_link= item.link
        :store_image= item.image
        :store_discription= item.description
      ></store-card>
    </slide>
   
    <hooper-navigation slot="hooper-addons"></hooper-navigation>
    <hooper-pagination slot="hooper-addons"></hooper-pagination>
  
  </hooper>
</template>

<script>

import storeCard from "./storeCard.vue";
import {
  Hooper,
  Slide,
  Progress as HooperProgress,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "App",
  props: { items: Array },
  components: {
    Hooper,
    Slide,
    HooperProgress,
    HooperPagination,
    HooperNavigation,
    storeCard
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 3,
        initialSlide: 0,
        itemsToSlide: 1,
        infiniteScroll: true,
        autoPlay: false,
        playSpeed: 3000,
        trimWhiteSpace: true,
        playSpeed: 2000,
        transition: 300,
        centerMode: true,
        pagination: "fraction",

        wheelControl:false,

        
      }
    };
  }
};
</script>
<style scoped>
#About {
  padding-top: 250px;
  min-height: 600px;
}

h1,
h4 {
  cursor: default;
}

a {
  text-decoration: none;
}

h1 {
  margin: 0;
  font-family: "Dosis", "Source Sans Pro", "Helvetica Neue", sans-serif;
  font-weight: 300;
  font-size: 4em;
  color: #2c3e50;
}

h4,
a {
  font-family: "Source Sans Pro", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #7f8c8d;
}

img.google {
  position: absolute;
  bottom: 0;
  left: 5px;
}

.container {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 240px;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(255, 255, 255, 1) 40%
  );
}

.row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.storeSlider .hooper-slide.is-current {
    transform: none !important;
}
</style>
