var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('header',{staticClass:"fixed-top d-flex align-items-center",attrs:{"id":"header"}},[(!this.$store.userTokenState)?_c('div',{staticClass:"container d-flex align-items-center justify-content-between"},[_vm._m(0),_c('nav',{class:'navbar' + (_vm.activeMobile ? ' navbar-mobile' : ''),attrs:{"id":"navbar"}},[_c('i',{class:'bi bi-list mobile-nav-toggle ' + (_vm.activeMobile ? ' bi-x' : ''),on:{"click":function($event){_vm.activeMobile = !_vm.activeMobile}}}),_c('ul',[_vm._m(1),(_vm.$i18n.locale == 'en')?_c('li',{on:{"click":function($event){_vm.activeMobile = !_vm.activeMobile}}},[_c('router-link',{attrs:{"to":{
              name: 'home_en',
            }}},[_vm._v(_vm._s(_vm.$t("home")))])],1):_c('li',{on:{"click":function($event){_vm.activeMobile = false}}},[_c('router-link',{attrs:{"to":{
              name: 'home',
            }}},[_vm._v(_vm._s(_vm.$t("home")))])],1),(_vm.$i18n.locale == 'en')?_c('li',[_c('router-link',{attrs:{"to":{
              name: 'about_en',
            }}},[_vm._v(_vm._s(_vm.$t("about-us")))])],1):_c('li',{on:{"click":function($event){_vm.activeMobile = false}}},[_c('router-link',{attrs:{"to":{
              name: 'about',
            }}},[_vm._v(_vm._s(_vm.$t("about-us")))])],1),(_vm.$i18n.locale == 'en')?_c('li',[_c('router-link',{attrs:{"to":{
              name: 'pricing_en',
            }}},[_vm._v(_vm._s(_vm.$t("pricing")))])],1):_c('li',{on:{"click":function($event){_vm.activeMobile = false}}},[_c('router-link',{attrs:{"to":{
              name: 'pricing',
            }}},[_vm._v(_vm._s(_vm.$t("pricing")))])],1),(_vm.$i18n.locale == 'en')?_c('li',{on:{"click":function($event){_vm.activeMobile = false}}},[_c('router-link',{attrs:{"to":{
              name: 'privacy_en',
            }}},[_vm._v(_vm._s(_vm.$t("privacyPolicy")))])],1):_c('li',{on:{"click":function($event){_vm.activeMobile = false}}},[_c('router-link',{attrs:{"to":{
              name: 'privacy',
            }}},[_vm._v(_vm._s(_vm.$t("privacyPolicy")))])],1),(_vm.$i18n.locale == 'en')?_c('li',[_c('router-link',{attrs:{"to":{
              name: 'questions_en',
            }}},[_vm._v(_vm._s(_vm.$t("questions")))])],1):_c('li',{on:{"click":function($event){_vm.activeMobile = false}}},[_c('router-link',{attrs:{"to":{
              name: 'questions',
            }}},[_vm._v(_vm._s(_vm.$t("questions")))])],1),(_vm.$i18n.locale == 'en')?_c('li',{on:{"click":function($event){_vm.activeMobile = false}}},[_c('router-link',{attrs:{"to":{
          name: 'blogs_en',
        }}},[_vm._v(_vm._s(_vm.$t("blogs")))])],1):_c('li',{on:{"click":function($event){_vm.activeMobile = false}}},[_c('router-link',{attrs:{"to":{
          name: 'blogs',
        
        }}},[_vm._v(_vm._s(_vm.$t("blogs")))])],1),(_vm.$i18n.locale == 'en')?_c('li',{on:{"click":function($event){_vm.activeMobile = false}}},[_c('router-link',{attrs:{"to":{
          name: 'contactus_en',
        }}},[_vm._v(_vm._s(_vm.$t("contactus")))])],1):_c('li',{on:{"click":function($event){_vm.activeMobile = false}}},[_c('router-link',{attrs:{"to":{
          name: 'contactus',
        
        }}},[_vm._v(_vm._s(_vm.$t("contactus")))])],1),_c('li',{staticClass:"mobile-active",on:{"click":function($event){_vm.activeMobile = false}}},[(_vm.$i18n.locale == 'ar')?_c('button',{on:{"click":function($event){return _vm.onChange('en')}}},[_vm._v(" English ")]):_vm._e(),(_vm.$i18n.locale == 'en')?_c('button',{on:{"click":function($event){return _vm.onChange('ar')}}},[_vm._v(" عربي ")]):_vm._e()]),(_vm.$i18n.locale == 'en')?_c('li',{staticClass:"mobile-active",on:{"click":function($event){_vm.activeMobile = false}}},[_c('router-link',{attrs:{"to":{
              name: 'loginphone',
              params: { storeName: this.$store.storeName },
            }}},[_vm._v(_vm._s(_vm.$t("CreateAccount")))])],1):_c('li',{staticClass:"mobile-active",on:{"click":function($event){_vm.activeMobile = false}}},[_c('router-link',{attrs:{"to":{
              name: 'loginphone_en',
              params: { storeName: this.$store.storeName },
            }}},[_vm._v(_vm._s(_vm.$t("CreateAccount")))])],1)])]),_c('div',[_c('nav',{staticClass:"navbar"},[_c('ul',[(_vm.$i18n.locale == 'ar')?_c('li',{staticClass:"LangBtn",on:{"click":function($event){return _vm.onChange('en')}}},[_c('button',[_vm._v(" English ")])]):_vm._e(),(_vm.$i18n.locale == 'en')?_c('li',{staticClass:"LangBtn",on:{"click":function($event){return _vm.onChange('ar')}}},[_c('button',[_vm._v(" عربي ")])]):_vm._e(),(_vm.$i18n.locale == 'en')?_c('li',{staticClass:"createAccountLI",on:{"click":function($event){_vm.activeMobile = false}}},[_c('router-link',{staticClass:"createAccount",attrs:{"to":{
              name: 'loginphone_en',
            }}},[_vm._v(_vm._s(_vm.$t("CreateAccount")))])],1):_c('li',{staticClass:"createAccountLI",on:{"click":function($event){_vm.activeMobile = false}}},[_c('router-link',{staticClass:"createAccount",attrs:{"to":{
              name: 'loginphone',
            }}},[_vm._v(_vm._s(_vm.$t("CreateAccount")))])],1)])])]),_vm._m(2)]):_vm._e()]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo mobile-inactive logo-1"},[_c('img',{attrs:{"src":"https://dekakeen.com/assets/image/logo.png","loading":"lazy","alt":"Dekakeen ,","width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mobile-active"},[_c('div',[_c('div',{staticClass:"logo d-flex flex-row justify-content-center align-items-center",staticStyle:{"height":"70px","margin-bottom":"20px"}},[_c('img',{attrs:{"src":"https://dekakeen.com/assets/image/logo.png","loading":"lazy","alt":"Dekakeen ","width":"100%","height":"100%"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo mobile-active"},[_c('img',{attrs:{"src":"https://dekakeen.com/websiteImages/logo.png","loading":"lazy","alt":"Dekakeen ,","width":"100%","height":"100%"}})])
}]

export { render, staticRenderFns }