var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"container"},[_c('h3',[_vm._v(_vm._s(_vm.$t("CreateNewAccount")))]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendPhone.apply(null, arguments)},"keydown":function($event){return _vm.form.onKeydown($event)}}},[_c('div',{staticClass:"formcontainer"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"input1"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Phone")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone_basic),expression:"form.phone_basic"}],staticClass:"input-phone",attrs:{"type":"phone","name":"phone","placeholder":"xxxx xxxxx","required":"","minlength":9,"maxlength":9},domProps:{"value":(_vm.form.phone_basic)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "phone_basic", $event.target.value)}}}),_vm._m(0)])]),_c('p',{staticStyle:{"color":"red","text-align":"center","margin":"10px"}},[_vm._v(" "+_vm._s(_vm.$t('code will be send to your phone'))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-1 col-sm-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agree_phone),expression:"agree_phone"}],staticClass:"agree_check",attrs:{"type":"checkbox","value":"1","required":""},domProps:{"checked":Array.isArray(_vm.agree_phone)?_vm._i(_vm.agree_phone,"1")>-1:(_vm.agree_phone)},on:{"change":function($event){var $$a=_vm.agree_phone,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.agree_phone=$$a.concat([$$v]))}else{$$i>-1&&(_vm.agree_phone=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.agree_phone=$$c}}}})]),_c('div',{staticClass:"col-lg-11 col-sm-11"},[_c('span',[_vm._v(_vm._s(_vm.$t('i agree'))+" ")]),_c('router-link',{staticClass:"item",attrs:{"to":{
            name: 'terms',
          }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Terms and Conditions')))])]),_c('router-link',{staticClass:"item",attrs:{"to":{
            name: 'Seller_Agreement',
          }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('and'))+" "+_vm._s(_vm.$t('Seller Agreement')))])]),_c('router-link',{staticClass:"item",attrs:{"to":{
            name: 'privacy',
          }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('and'))+" "+_vm._s(_vm.$t("privacyPolicy")))])]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("Own from the platform")))])],1)]),_c('div',{staticClass:"d-flex flex-row justify-content-center"},[_c('button',{staticClass:"btn",attrs:{"type":"submit","disabled":_vm.form.busy}},[_vm._v(" "+_vm._s(_vm.$t("continue"))+" "),_c('i',{class:_vm.$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'})])])])]),_c('div',{staticClass:"d-flex flex-row justify-content-center loginBtn"},[_c('a',{attrs:{"href":"https://dekakeen.com/dashboard/login_my_market"}},[_vm._v(_vm._s(_vm.$t('HaveAccount')))])]),(_vm.validateMessage!='')?_c('div',{staticStyle:{"text-align":"center","margin-top":"10px"}},[_c('span',{staticStyle:{"color":"red !important","margin":"0"}},[_vm._v(_vm._s(_vm.validateMessage)+"*")])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"flag"},[_c('h2',[_vm._v("+966")]),_c('img',{attrs:{"src":"https://dekakeen.com/images/flag/1673850628_2100656867.png","alt":"Dekakeen,Saudia","width":"100%","height":"100%","loading":"lazy"}})])
}]

export { render, staticRenderFns }