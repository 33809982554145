<template>
  <div id="About">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>

    <section  v-if="!loading">
      <!--<div class="container" v-html="DBitems.title"></div>-->
      <div class="container" v-html="DBitems.description">
        <!--v-html=""-->
      </div>
    </section>
  </div>
</template>
<script>
export default {
  components: {},
  data: function() {
    return {
      loading: false,
      DBitems: null
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get("https://dekakeen.com/api/website/page/aboutus", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => {
        this.DBitems = response.data.data.page;
      if(this.DBitems.seo_description)
        document.getElementById("description").content=
        this.DBitems.seo_description;
        if(this.DBitems.seo_keyword)
        document.getElementById("keyword_meta").content=this.DBitems.seo_keyword;
        if(this.DBitems.seo_title)
        document.title = this.DBitems.seo_title;
       //end seo


      })
      .then(() => (this.loading = false));
  },
  methods: {
    updatedata() {
      this.loading = true;
      this.axios
        .get("https://dekakeen.com/api/website/page/aboutus", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.DBitems = response.data.data.page;

          if(this.DBitems.seo_description)
        document.getElementById("description").content=
        this.DBitems.seo_description;
        if(this.DBitems.seo_keyword)
        document.getElementById("keyword_meta").content=this.DBitems.seo_keyword;
        if(this.DBitems.seo_title)
        document.title = this.DBitems.seo_title;
        })
        .then(() => (this.loading = false));
    }
  }
};
</script>

<style scoped>
#About {
  padding-top: 150px;
  min-height: 600px;
}
@media (max-width: 992px) {
  #About {
  padding-top: 85px;
  min-height: 600px;
}
}
</style>
