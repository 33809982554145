<template>
    <div id="About" class="contactus_page">
      <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
  
      <section  v-if="!loading">
        <div vlass="title_background">
        <h1>{{ $t('contactus') }}</h1>
        </div>
          <div class="row" style="background-color: white;">
            <div class="col-lg-4">
              <div class="card-contact-info">
                <i class="bi bi-envelope"></i>
               <div class="info-meta">
                <h6  v-for="(item, index) in social.email" :key="index">
                 {{ item }}
                </h6>
                </div>
                </div>
              </div>

              <div class="col-lg-4">
              <div class="card-contact-info">
                <i class="bi bi-telephone-outbound"></i>
                <div class="info-meta">
                <h6 dir="ltr" >{{ social.phone }}</h6>
                <h6  dir="ltr" v-if="social.other_phone!=null">{{ social.other_phone }}</h6>
                </div>
                </div>
              </div>


              <div class="col-lg-4">
              <div class="card-contact-info">
                <i class="bi bi-geo-alt"></i>
                <div class="info-meta">
                <h6>{{ social.address }}</h6>
                </div>
                </div>
              </div>


            </div>



        <div class="container" >
          
            <div class="row">
                <div class="col-lg-6">
                    <div class="card_form">
                      <h3>{{ $t('contactus_title') }}</h3>
                        <p>{{ $t('contactus_description') }}</p>
                      <contactCard></contactCard>
                      </div>
                      </div>

                    <div class="col-lg-6">
                        <img src="https://dekakeen.com/websiteImages/contactus/contact4.png" class="contact_img" 
                        loading="lazy"
                 alt="Dekakeen ,Contact Us"
                 width="100%"
                 height="100%"
                        />
                        <div class="mt-5 d-flex justify-content-around social-link">
              <a v-if="social.tiktok" :href="social.tiktok">
                <img src="https://dekakeen.com/websiteImages/tik-tok.png" 
                loading="lazy"
                 alt="Dekakeen ,TikTok"
                 width="100%"
                 height="100%"
                />
              </a>
              <a v-if="social.youtube" :href="social.youtube">
                <img src="https://dekakeen.com/websiteImages/youtube.png"
                loading="lazy"
                 alt="Dekakeen ,YouTube"
                 width="100%"
                 height="100%"
                 />
              </a>
              <a v-if="social.instgram" :href="social.instgram">
                <img  src="https://dekakeen.com/websiteImages/instagram.svg" 
                
                loading="lazy"
                 alt="Dekakeen ,Instgram"
                 width="100%"
                 height="100%"
                />
              
              </a>
              <a v-if="social.facebook" :href="social.facebook">
                <img  src="https://dekakeen.com/websiteImages/facebook.svg"
                loading="lazy"
                 alt="Dekakeen ,FaceBook"
                 width="100%"
                 height="100%"
                 />
              
              </a>
            </div>
                      </div>


                </div>
        </div>



      </section>
    </div>
  </template>
  <script>
  import contactCard from "../components/contactCard.vue";
  export default {
    components: { contactCard },
    data: function() {
      return {
        loading: false,
        DBitems: null,
        social:null,
      };
    },
    created() {
      this.loading = true;
      this.axios
        .get("https://dekakeen.com/api/website/page/contactus", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.DBitems = response.data.data.page;
        if(this.DBitems.seo_description)
          document.getElementById("description").content=
          this.DBitems.seo_description;
          if(this.DBitems.seo_keyword)
          document.getElementById("keyword_meta").content=this.DBitems.seo_keyword;
          if(this.DBitems.seo_title)
          document.title = this.DBitems.seo_title;
         //end seo
  
  
        })
        .then(() => (this.loading = false));

         //////////////////////
         this.loading = true;
    this.axios
      .get("https://dekakeen.com/api/website/social_media_info", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => {
        if (response.data.code == "1") {
          this.social = response.data.data.social;
        }
      })
      .then(() => (this.loading = false));
    },
    methods: {
      updatedata() {
        this.loading = true;
        this.axios
          .get("https://dekakeen.com/api/website/page/contactus", {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          })
          .then(response => {
            this.DBitems = response.data.data.page;
  
            if(this.DBitems.seo_description)
          document.getElementById("description").content=
          this.DBitems.seo_description;
          if(this.DBitems.seo_keyword)
          document.getElementById("keyword_meta").content=this.DBitems.seo_keyword;
          if(this.DBitems.seo_title)
          document.title = this.DBitems.seo_title;
          })
          .then(() => (this.loading = false));

           //////////////////////
         this.loading = true;
    this.axios
      .get("https://dekakeen.com/api/website/social_media_info", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => {
        if (response.data.code == "1") {
          this.social = response.data.data.social;
        }
      })
      .then(() => (this.loading = false));
      }
    }
  };
  </script>
  
  <style scoped>
  #About {
    padding-top: 150px;
    min-height: 600px;
  }
  @media (max-width: 992px) {
    #About {
    padding-top: 85px;
    min-height: 600px;
  }
  }
  @media (min-width: 1000px) {
  .card_form {
    margin-top: 15%;
}
  }

 .card_form h2 {
    display: none !important;
}
.card_form h3 {
text-align:left
}
[dir="rtl"] .card_form h3 {
text-align:right
}

  .contact_img
  {
    width: 100%;
    object-fit: contain;
    max-height: 400px;
  }
  .card-contact-info {
    text-align: center;
    /* border: 1px solid #453672; */
    padding: 5%;
    /* border-radius: 15px; */
    margin:5%;
}

.card-contact-info i {
  text-align: center;
    padding: 5%;
    font-size: 30px;
    color: #ffffff;
    padding: 8px 12px;
    border: 2px solid var(--third-color);
    border-radius: 50px;
    background-color: #40316f;
}
.info-meta
{
  margin:8%;
}

section h1 {
  font-family: "GraphikArabic-Bold";
  font-size: 2em;
  line-height: 50px;
  background: linear-gradient(
    45deg,
    var(--primary-color),
    #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  text-align:center;
  margin-bottom:5%;
}

.social-link img {
    filter: invert(1);
    background-color: black;
    max-width: 40px;
    padding: 5px;
    border-radius: 50px;
    border: 2px solid #6b1010;
}
input[type="tel"]
{
  text-align:right
}
  </style>
  