<template>
  <a :href="store_link"  target="_blank" rel="noopener noreferrer">
    <div class="card">
      <div class="d-flex flex-row justify-content-center">
        <div class="image">
          
          <img :src="store_image" :alt="store_name" loading="lazy" width="100%" height="100%"/>
        </div>
      </div>
      <div class="text-card" style="text-align: center; margin-top: 10px">
        <h5>{{ store_name }}</h5>
        <p>{{ store_discription }}</p>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    store_id:Number,
    store_name:String,
    store_link:String,
    store_image:String,
    store_discription:String,
  },
  data: function () {
    return {};
  },
};
</script>

<style scoped>
.card {
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
}
.text-card{
    position: absolute;
    top: 60%;
    border: 0;
    border-radius: 15px;
    width: 80%;
    margin: 100px 10% 0 10%;
    padding: 20px 20px 5px;
    background-color: #fff;
}
.card h5 {
  color: var(--primary-color);
  font-family: "GraphikArabic-Medium";
  font-size: 16px;
}
.card p {
  color: var(--secondary-color);
  font-family: "GraphikArabic-Medium";
  font-size: 13px;
}
.image {

  aspect-ratio: 3/2.2
}
img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}
.card:hover {
  color: var(--primary-color);
  transform: scale(1.02);
}


@media (max-width: 575px) {
.text-card{
    position: absolute;
    top: 60%;
    border: 0;
    border-radius: 15px;
    width: 90%;
    margin: 100px 5% 0 5%;
    padding: 10px 10px 0;
    background-color: #fff;
}
.card h5 {
  color: var(--primary-color);
  font-family: "GraphikArabic-Medium";
  font-size: 12px;
}
.card p {
  color: var(--secondary-color);
  font-family: "GraphikArabic-Medium";
  font-size: 10px;
  margin-bottom: 5px;
}
.image {

  aspect-ratio: 1200/400
}
img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}
.card:hover {
  color: var(--primary-color);
  transform: scale(1.02);
}
}
</style>
