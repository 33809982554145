<template>
  <section>
 
<!--  -->
<div class="container">
      <h3>{{ $t("CreateNewAccount") }}</h3>

      <form @submit.prevent="sendPhone" @keydown="form.onKeydown($event)">
        <div class="formcontainer">
          <div class="row justify-content-center">
          
            <div class="col-lg-8">
              <div class="input1">
              <h4>{{ $t("Phone") }}</h4>
              <input
              class="input-phone"
                type="phone"
                name="phone"
                v-model="form.phone_basic"
                placeholder="xxxx xxxxx"
                required
                :minlength=9
                :maxlength=9
               

              />
            <span class="flag">  <h2>+966</h2>
              <img src="https://dekakeen.com/images/flag/1673850628_2100656867.png" 
              alt="Dekakeen,Saudia"
              width="100%"
              height="100%"
              loading="lazy"
              />
            </span>
            </div>
            
            </div>
            <p style="color:red; text-align:center; margin:10px"  >
              {{ $t('code will be send to your phone') }}
            </p>
            <div class="row">
             <div class="col-lg-1 col-sm-1">
            <input type="checkbox"    v-model="agree_phone" value="1" class="agree_check" required/>
            </div>
            <div class="col-lg-11 col-sm-11">
              <span>{{ $t('i agree') }} </span>
              <router-link
            class="item"
            :to="{
            name: 'terms',
          }"
            >
            <span> {{ $t('Terms and Conditions') }}</span>
            </router-link>

            <router-link
            class="item"
            :to="{
            name: 'Seller_Agreement',
          }"
            >
            <span> {{$t('and')}} {{ $t('Seller Agreement') }}</span>
            </router-link>

            <router-link
            class="item"
            :to="{
            name: 'privacy',
          }"
            >
            <span> {{$t('and')}} {{ $t("privacyPolicy") }}</span>
            </router-link>
            <span> {{ $t("Own from the platform") }}</span>
            </div>
           
            </div>
           
            <!--editttttt check if match-->
            <div class="d-flex flex-row justify-content-center">
              <button type="submit" :disabled="form.busy" class="btn">
                {{ $t("continue") }}
                <i
                  :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
                ></i>
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-center loginBtn">
          <a href="https://dekakeen.com/dashboard/login_my_market">{{ $t('HaveAccount') }}</a>
        </div>
        <div v-if="validateMessage!=''" style="text-align: center; margin-top: 10px;">
          <span style="color: red !important; margin: 0;">{{ validateMessage }}*</span>
        </div>
      </form>
    </div>
<!--  -->

 <!-- endlogin -->
</section>
  </template>
  <script>

import Form from "vform";

export default {
  components: {  },
  data: function() {
    return {
      isValid: true,
      partphone: null,
      loading: true,
      validateMessage: "",
      DBitems: null,

      errorMessage: "",
      form: new Form({
        phone_basic: this.phone,
        phone: this.phone,
      })
    };
  },
  props: {
    package_id: Number
  },
  created() {
    console.log('package 1 login',this.package_id)

    if (this.package_id == null) {
      if(this.$i18n.locale == 'en')
      this.$router.push({
        name: "pricing_en"
      });
      else
      this.$router.push({
        name: "pricing"
      });
    }
    else{
     this.loading = false;
    }
  },
  mounted() {
    if (this.package_id != null) {
    this.loading = true;
    if (this.$i18n.locale == "ar") {
      document
        .getElementsByClassName("input-tel__input")[0]
        .setAttribute("placeholder", "رقم الهاتف");
      document.getElementsByClassName("country-selector__label")[0].innerHTML =
        "رمز الدولة";
      document.getElementsByClassName("input-tel__label")[0].innerHTML =
        "* رقم الهاتف";
    } else {
      document
        .getElementsByClassName("input-tel__input")[0]
        .setAttribute("placeholder", "phone number");
      document.getElementsByClassName("country-selector__label")[0].innerHTML =
        "country code";
      document.getElementsByClassName("input-tel__label")[0].innerHTML =
        "phone number *";
    }
    this.loading = false;
    }
  },
  computed: {
    myState: function() {
      return this.$store.state.langchange; // return the state value in `my_state`
    }
  },
  watch: {
    myState: function() {
      this.loading = true;
      if (this.$i18n.locale == "ar") {
        document
          .getElementsByClassName("input-tel__input")[0]
          .setAttribute("placeholder", "رقم الهاتف");
        document.getElementsByClassName(
          "country-selector__label"
        )[0].innerHTML = "رمز الدولة";
        document.getElementsByClassName("input-tel__label")[0].innerHTML =
          "* رقم الهاتف";
      } else {
        document
          .getElementsByClassName("input-tel__input")[0]
          .setAttribute("placeholder", "phone number");
        document.getElementsByClassName(
          "country-selector__label"
        )[0].innerHTML = "country code";
        document.getElementsByClassName("input-tel__label")[0].innerHTML =
          "phone number *";
      }
      this.loading = false;
    }
  },
  methods: {
    async sendPhone() {
      this.form.phone='+966'+this.form.phone_basic
      if (this.form.confirmPassword == this.form.password) {
        if (this.isValid == true) 
        {
          await this.form
            .post("https://dekakeen.com/api/website/genert_codebywhatsappv1", {
              form: this.form,
              headers: {
                "Accept-Language": this.$i18n.locale
              }
            })
            .then(response => {
              if (response.data.code == "-1") {
                this.validateMessage = response.data.message;
                setTimeout(() => {
                  this.validateMessage = "";
                }, 3000);
              } else if (response.data.code == "1") {
                if(this.$i18n.locale == 'en')
                {
                this.$router.push({
                  name: "activecode_en",
                  params: {
                    phone: this.form.phone,
                    package_id: this.package_id
                  }
                });
              }

              else
              {
                this.$router.push({
                  name: "activecode",
                  params: {
                    phone: this.form.phone,
                    package_id: this.package_id
                  }
                });
              }
                //console.log( response.data.code);
                //console.log( response.data.message);
              }
            });
        } else {
          if (this.$i18n.locale == "en") {
            this.validateMessage = "your number isn't valid";
            setTimeout(() => {
              this.validateMessage = "";
            }, 3000);
          } else {
            this.validateMessage = "هذا الرقم غير صالح";
            setTimeout(() => {
              this.validateMessage = "";
            }, 3000);
          }
        }
      } else {
        console.log(this.form.confirmPassword + " " + this.form.password);
        if (this.$i18n.locale == "en") {
          this.validateMessage = "the password not matching";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        } else {
          this.validateMessage = "كلمتا السر غير متطابقتين";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        }
      }
    },
    sendPhone1(event) {
      this.isValid = event.isValid;
      if (this.isValid == true) {
        this.form.phone = event.e164; //+ (' +' +event.countryCallingCode));
        //console.log(this.form.phone)
      }
      //console.log(event.isValid);
      //console.log(event);
    }
  }
};
</script>
<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#Second {
  padding-top: 100px;
  min-height: 700px;
  background-color: #fdfefe;
}
.input1 {
  width: 100%;
  margin-top: 30px;
}
#Second {
  margin-top: 70px;
}

input {
  border: 0;
  border: 2px solid #eae9f2;
  background-color: #fff;
  width: 100%;
  height: 32px;
  /*margin-top: 1%;*/
  border-radius: 15px;
  color: var(--secondary-color);
  padding: 25px 10px 30px 10px;
}

h3 {
  font-family: "GraphikArabic-Bold";
  font-size: 2em;
  line-height: 40px;
  background: linear-gradient(
    45deg,
    var(--primary-color),
    #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  text-align: center;
  margin-bottom: 30px;
}
h4 {
  font-size: 16px;
  font-family: "GraphikArabic-Medium";
  color: #696871;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn {
  /*border: 2px solid var(--primary-color);*/
  background-color: var(--third-color);
  border-radius: 30px;
  padding: 10px 8px 10px 30px;
  font-size: 18px;
  font-family: "GraphikArabic-Medium";
  color: var(--primary-color);
  font-weight: 700;
  margin-top: 40px;
  text-decoration: none;
  z-index: 200;
}
.btn:hover {
  transform: scale(1.05);
  background-color: var(--third-color);
}
[dir="rtl"] .btn {
  padding: 10px 30px 10px 8px;
}
.btn i {
  font-size: 25px;
  background-color: #ffe9c8;
  border-radius: 50px;
  padding: 4px 14px;
  margin: 0 0 0 10px;
}
[dir="rtl"] .btn i {
  margin: 0 10px 0 0;
}

.icon {
  margin-top: -120px;
}
.formcontainer {
  width: 60%;
  margin: 0 20%;
}
.loginBtn a {
  text-decoration: underline !important;
  text-underline-offset: 5px;
  font-size: 16px;
  font-family: "GraphikArabic-Medium";
  color: #696871;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}

.flag
    {
        margin-top: -51px !important;
        float: left !important;
        /* height: 40px !important; */
        border-radius: 40% !important;
        /* width: 50px !important; */
        display: inline-flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        align-content: stretch;
        justify-content: center;
        direction: ltr;
    }

    .flag img{
        width: 45px;
    height: 44px;
    border-radius: 35px;
    border: 1px solid var(--primary-color);
    margin-left: 6px;
    }
    .flag h2
    {   
       font-size: 16px !important;
        margin-top: 10px;
        padding: 0 10px;
        color:var(--primary-color);
        }
.flag{
margin-top: 10px !important;
    float: left !important;
    height: 56px !important;
    border-radius: 40% !important;
    width: 50px !important;
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    align-content: stretch;
    justify-content: center;
    direction: ltr;
    position: absolute !important;
    z-index: 99999;
    left: 37%;
    /* margin-right: 50%; */
}

@media only screen and (max-width: 700px)
{
    .flag {
   
    left: 28% !important;
}
    
    input.input-phone
    {
        text-align:right !important;
    }
    
    .flag {
   
    left: 30% !important;
}
}

@media only screen and (min-width: 800px)
{
    .flag {
   
    left: 28% !important;
}
    
    input.input-phone
    {
        text-align:center !important;
    }
    
    .flag {
   
    left: 38% !important;
}
}
.agree_check
{
  height:20px
}

</style>