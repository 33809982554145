<template>
   <div class="card_blog col-lg-4 col-sm-6">
<router-link v-if="$i18n.locale=='ar'"
:to="{
      name: 'blog',
      params: { categoryName: this.category, id: this.id  , name:this.name},
          }"
  class="post-entry__thumb">
<img :src="this.image " loading="lazy" width="400" class="blog_gird_img" height="200" :alt="this.name"/>
</router-link>
<router-link v-else
:to="{
      name: 'blog_en',
      params: { categoryName: this.category, id: this.id  , name:this.name},
          }"
  class="post-entry__thumb">
<img :src="this.image " loading="lazy" width="400" height="200" :alt="this.name"/>
</router-link>
<div class="post-entry__meta">
<router-link v-if="$i18n.locale=='ar'" :to="{
      name: 'blogs_category',
      params: { categoryName: this.category, id:this.category_id},
          }" class="inline-block badge badge--xs mb-1 me-2 blog_category"> {{ this.category }}
          </router-link>

          <router-link v-else :to="{
      name: 'blogs_category_en',
      params: { categoryName: this.category, id:this.category_id},
          }" class="inline-block badge badge--xs mb-1 me-2 blog_category"> {{ this.category }}
          </router-link>

          <router-link
          v-if="$i18n.locale=='ar'"
           :to="{
      name: 'blog',
      params: { categoryName: this.category, id: this.id  , name:this.name},
          }"
 ><h4 class="blog_title">{{ this.name }} </h4>
</router-link>
<router-link
          v-else
           :to="{
      name: 'blog_en',
      params: { categoryName: this.category, id: this.id  , name:this.name},
          }"
 ><h4 class="blog_title">{{ this.name }} </h4>
</router-link>
</a>
</article>
<time :datetime=this.date itemprop="datePublished" class="text-xs mt-2">{{ this.date }}</time>

</div>
    </div>
  </template>
  <script>
  
  export default {
    data() {
      return {
        loading : false,
        DBitems: null
      };
    },
    props: {
    id: Number,
    category_id: Number,
    name: String,
    description: String,
    image: String,
    category: String,
    date: String,
    
  },
    created() {
    
    },
    methods: {
      
    }
  };
  </script>
  <style>
.post-entry__thumb {
    --tw-bg-opacity: 1;
    aspect-ratio: 2/1;
    background-color: rgb(248 248 248/var(--tw-bg-opacity));
    border-radius: 0.5rem;
    display: block;
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
}

.post-entry__thumb img
{
  object-fit: cover;
}
.blog_title {
    font-size: 19px;
    color: var(--primary-color);
    min-height: 50px;
}
.card_blog
{
  margin:5% 0px;
  min-height:200px;

}
.blog_category
{
  color:var(--third-color) !important;
}
.blog_gird_img:hover
{
  transform: scale(2);
  }
</style>
