import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import Loginphone from '../views/Loginphone.vue';

import AboutView from '../views/About.vue';
import TermsView from '../views/Terms.vue';
import Seller_AgreementView from '../views/Seller_Agreement.vue';

import failed_payment from '../views/failed_payment.vue'

import PrivacyView from '../views/Privacy.vue';
import PricingView from '../views/Pricing.vue';
import QuestionsView from '../views/Questions.vue';

import activecode from '../views/activecode.vue';
import invoice from '../views/invoice.vue';
import blog from '../views/blog.vue';
import blogs from '../views/blogs.vue';
import blogs_category from '../views/blogs_category.vue';
import contactus from '../views/contactus.vue';


Vue.use(VueRouter)
const routes = [
    {
            path: '/',
            name: 'home',
            component: HomeView
        },
        
        {
            path: '/about',
            name: 'about',
            component: AboutView,
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: PrivacyView,
        },
        {
            path: '/terms',
            name: 'terms',
            component: TermsView,
        },
        {
            path: '/contactus',
            name: 'contactus',
            component: contactus,
        },
        {
            path: '/Seller_Agreement',
            name: 'Seller_Agreement',
            component: Seller_AgreementView,
        },
        {
            path: '/pricing',
            name: 'pricing',
            component: PricingView,
        },
        {
            path: '/questions',
            name: 'questions',
            component: QuestionsView,
        },
        
        {
            path: '/loginphone',
            name: 'loginphone',
            component: Loginphone,
            props: true
        },
      
        {
            path: '/activecode',
            name: 'activecode',
            component: activecode,
            props: true
        },
    
        {
            path: '/invoice',
            name: 'invoice',
            component: invoice,
            props: true
        },
        {
            path: '/failed_payment/:id',
            name: 'failed_payment',
            component: failed_payment,
            props: true
        },
        {
            path: '/:categoryName/blog/:id/:name',
            name: 'blog',
            component: blog,
            props: true
        },
        {
            path: '/blogs',
            name: 'blogs',
            component: blogs,
            props: true
        },
        {
            path: '/:categoryName/blogs/:id',
            name: 'blogs_category',
            component: blogs_category,
            props: true
        },



        ///arabic  link
        
        

        {
            path: '/en',
            name: 'home_en',
            component: HomeView
        },
       
        {
            path: '/en/about/',
            name: 'about_en',
            component: AboutView,
        },
        {
            path: '/en/privacy',
            name: 'privacy_en',
            component: PrivacyView,
        },
        {
            path: '/en/terms',
            name: 'terms_en',
            component: TermsView,
        },
        {
            path: '/en/Seller_Agreement',
            name: 'Seller_Agreement_en',
            component: Seller_AgreementView,
        },
        {
            path: '/en/pricing',
            name: 'pricing_en',
            component: PricingView,
        },
        {
            path: '/en/questions',
            name: 'questions_en',
            component: QuestionsView,
        },
        

        {
            path: '/en/loginphone',
            name: 'loginphone_en',
            component: Loginphone,
            props: true
        },

        {
            path: '/en/activecode',
            name: 'activecode_en',
            component: activecode,
            props: true
        },

        {
            path: '/en/invoice',
            name: 'invoice_en',
            component: invoice,
            props: true
        },
        {
            path: '/en/failed_payment/:id',
            name: 'failed_payment_en',
            component: failed_payment,
            props: true
        },
        {
            path: '/en/:categoryName/blog/:id/:name',
            name: 'blog_en',
            component: blog,
            props: true
        },
       
        {
            path: '/en/blogs',
            name: 'blogs_en',
            component: blogs,
            props: true
        },
        {
            path: '/en/:categoryName/blogs/:id',
            name: 'blogs_category_en',
            component: blogs_category,
            props: true
        },
        {
            path: '/en/contactus',
            name: 'contactus_en',
            component: contactus,
        },
    
    ]

const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { x: 0, y: 0 }
    },
    mode: 'history',
    //base: 'website' + process.env.BASE_URL,
     base: process.env.BASE_URL,
    //base: "/",
    routes,

})


export default router