<template>
  <div>
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <!-- ======= Second Section ======= -->
    <section>
     
      <div dir="ltr" class="col-lg-12" v-if="!loading">
        <div class="heroCarousel">
          <carousel
            :autoplayTimeout="4000"
            :per-page="1"
            :autoplayHoverPause="false"
            :mouse-drag="false"
            autoplay
            loop
            :paginationEnabled="false"
          >
            <slide v-for="(item, idx) in DBitems" :key="idx">
              <div class="container1" :style=" { backgroundImage: 'url(\''+item.image+'\')' }">
                <!-- + item.image + -->
                <section
                  data-aos="fade"
                  data-aos-duration="3000"
                  class="slider d-flex justify-content-center"
                  id="slider"
                  style="height: 100%;"
                >
                  <div class="container text-center">
                    <div class="row all-content">
                      <div class="col-12">
                        <div class="left-content">
                          <!--<p class="hello">
                          {{ $t('Title') }}</p>-->
                          <!-- Animated Text-->
                          <b class="hello">{{ item.title}}</b>
                          <div class="animated-headlines">
                            <h1 class="cd-headline clip is-full-width">
                              <span class="cd-words-wrapper">
                                <b>{{ item.sub_title}}</b>
                                <!--<b>UI/UX Designer</b>-->
                              </span>
                            </h1>
                          </div>
                          <router-link class="btn" dir="auto" 
                          v-if="$i18n.locale == 'en'"
                          :to="{
                              name: 'loginphone_en',
                            }">
                            {{ item.btn_title }}
                            <i
                              :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
                            ></i>
                            
                          </router-link>


                          <router-link class="btn" dir="auto" 
                          v-else
                          :to="{
                              name: 'loginphone',
                            }">
                            {{ item.btn_title }}
                            <i
                              :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
                            ></i>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </slide>
          </carousel>
        </div>
       
      </div>
    </section>
    <!-- End Second -->
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      loading: false,
      DBitems: null
    };
  },
  created() {
    this.loading = true;
    //////////////////////home//////////////////////
    this.axios
      .get("https://dekakeen.com/api/website/sliders_website", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => (this.DBitems = response.data.data.sliders))
      .then(() => (this.loading = false));
  },
  methods: {
    updatedata() {
      this.loading = true;
      //////////////////////home//////////////////////
      this.axios
        .get("https://dekakeen.com/api/website/sliders_website", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => (this.DBitems = response.data.data.sliders))
        .then(() => (this.loading = false));
    }
  }
};
</script>
<style scoped>
/* ==========================
   [02]. Slider
========================== */
.container1 {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  aspect-ratio: 1200/400;
}

img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.heroCarousel {
 
  border-radius: 15px;
  padding: 0;
}

.slider {
  font-family: "GraphikArabic-Medium";
  background-color: transparent;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
}

.slider .particels {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.animated-headlines .cd-headline span {
  font-size: 50px;
  font-weight: 700;
  color: #e0a80d;
}

.slider .left-content .hello {
  font-size: 50px;
  font-weight: 700;
  color: var(--primary-color);
  text-shadow: 0 10px 10px #fff;
}

.slider .left-content h2 {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  padding: 10px 0;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  display: inline-block;
  margin: 5px 0 20px 0;
}

.slider .left-content .paragraph {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  padding-right: 20px;
}

.slider .left-content .btn {
  /*border: 2px solid var(--primary-color);*/
  background-color: var(--third-color);
  border-radius: 30px;
  padding: 10px 8px 10px 30px;
  font-size: 18px;
  font-family: "GraphikArabic-Medium";
  color: var(--primary-color);
  font-weight: 700;
  margin-top: 30px;
  /*margin-bottom: 50px;*/
  text-decoration: none;
  z-index: 200;
  animation: animateBtn 0.7s alternate infinite;
}
.slider .left-content .btn:hover {
  transform: scale(1.05);
}
[dir="rtl"] .slider .left-content .btn {
  padding: 10px 30px 10px 8px;
}
.slider .left-content .btn i {
  font-size: 25px;
  background-color: #ffe9c8;
  border-radius: 50px;
  padding: 4px 14px;
  margin: 0 0 0 10px;
}
[dir="rtl"] .slider .left-content .btn i {
  margin: 0 10px 0 0;
}

.cd-headline b {
  position: relative;
  font-family: "GraphikArabic-Bold";
  font-size: 1em;
  line-height: 100px;
  overflow: hidden;
  color: var(--primary-color);
  text-shadow: 0 10px 15px #fff;

}
[dir="rtl"] .cd-headline b {
  animation: animate1 10s linear infinite;
}

/************************************************************************* */

@media (max-width: 992px) {
  .container1 {
    padding-bottom: 0 !important;
    aspect-ratio: 1200/500;
    background-position:center;
  }
  .animated-headlines .cd-headline span{
    font-size: 25px;
  }
  .slider{
    margin-top: 55px;
  }
  .slider .left-content .btn{
    margin-top: 5px;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 630px) {
}

@media (max-width: 575px) {
  section {
    /*margin-top: 40px !important;*/
    padding-bottom: 10px !important;
  }
  .animated-headlines .cd-headline span {
    font-size: 16px;
  }
  .container1 {
    padding-bottom: 0 !important;
    aspect-ratio: 1200/500;
    background-position:center;
  }
  .slider .left-content .hello {
    font-size: 16px;
  }

  .slider .left-content h2 {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 0;
    margin: 5px 0 20px 0;
  }

  .slider .left-content .paragraph {
    font-size: 14px;
    padding-right: 20px;
  }

  .slider .left-content .btn {
    padding: 5px 15px 5px 5px !important;
    font-size: 12px;
    font-weight: 700;
    margin-top: 5px;
  }
  .slider .left-content .btn i {
    font-size: 15px;
    padding: 0 4px;
  }
  .cd-headline b {
    line-height: inherit;
  }
}

@media (max-width: 400px) {
}
</style>
