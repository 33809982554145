<template>
  <section id="Questions">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <div v-if="!loading" class="container">
      <h3>{{ $t('questions') }}</h3>
      <VueFaqAccordion 
        :items="DBitems"
        activeColor='#017598'
      />
    </div>
  </section>
</template>
<script>
import VueFaqAccordion from 'vue-faq-accordion'
export default {
  components: {
    VueFaqAccordion
  },
  data: function() {
    return {
      loading: false,
      DBitems: null,
      seo: null,
       myItems: [
          {
            title: 'How many time zones are there in all?',
            value: 'Given a 24-hour day and 360 degrees of longitude around the Earth',
          }
        ]
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get("https://dekakeen.com/api/website/questions", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => {
        this.DBitems = response.data.data.questions;
        this.seo=response.data.data.page;
       

          //
         if(this.seo.seo_description)
        document.getElementById("description").content=
       this.seo.seo_description;

        if(this.seo.seo_keyword)
         document.getElementById("keyword_meta").content=this.seo.seo_keyword;
       if(this.seo.seo_title)
       document.title = this.seo.seo_title;
      })
      .then(() => (this.loading = false));
  },
  methods: {
    updatedata() {
      this.loading = true;
      this.axios
        .get("https://dekakeen.com/api/website/questions", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.DBitems = response.data.data.questions;
          this.seo=response.data.data.page;

         //
         if(this.seo.seo_description)
        document.getElementById("description").content=
        this.seo.seo_description;

        if(this.seo.seo_keyword)
        document.getElementById("keyword_meta").content=this.seo.seo_keyword;
        if(this.seo.seo_title)
        document.title = this.seo.seo_title;
         ////



        })
        .then(() => (this.loading = false));
     
    }
  }
};
</script>

<style scoped>
#Questions {
  padding-top: 150px;
  min-height: 600px;
}
[dir="ltr"] .card {
  border-left: 3px solid var(--primary-color);
  border-radius: 0px;
  padding: 10px;
  margin-bottom: 20px;
  height: 90%;
}
[dir="rtl"] .card {
  border-right: 3px solid var(--primary-color);
  border-radius: 0px;
  padding: 10px;
  margin-bottom: 20px;
  height: 90%;
}
i {
  color: darkgreen;
}
h4 {
  color: var(--primary-color);
}
h6 {
  color: #6f6e6e;
  width: 90%;
  margin: 20px 5% 40px;
  height: 75px;
  overflow: auto;
}
section h3 {
  color: var(--primary-color);
  margin-top: 20px;
  margin-bottom: 20px;
}
/*.faq-wrapper{
    max-width: 825px;
}*/
@media (max-width: 992px) {
  #Questions {
  padding-top: 85px;
  min-height: 600px;
}
}
</style>

