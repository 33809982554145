var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":{
                name: 'loginphone',
                params:{
                  package_id: this.id
                }
              }}},[_c('div',{staticClass:"card",attrs:{"data-aos":"fade-up","data-aos-duration":"2000"}},[_c('div',{staticClass:"text-card",staticStyle:{"text-align":"center","margin-top":"10px"}},[_c('h5',{staticStyle:{"font-size":"20px !important"}},[_vm._v(_vm._s(_vm.name)+" "),_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.sub_name))])]),_c('h5',{staticStyle:{"font-size":"20px !important"}},[_vm._v(_vm._s(_vm.$t('your store')))]),_c('p',[_vm._v(_vm._s(_vm.description))]),_c('hr')]),_c('div',{staticClass:"d-flex flex-row justify-content-center mb-3"},[_c('ul',_vm._l((_vm.features),function(item,index){return _c('li',{key:index,class:item.state ? 'trueMark' :'falseMark'},[_c('h6',[_vm._v(_vm._s(item.option))])])}),0)]),_c('div',[_c('h5',[_vm._v(_vm._s(_vm.price)+" / "+_vm._s(_vm.$t('year'))+" "),_c('br'),_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.period))])]),(_vm.$i18n.locale == 'en')?_c('router-link',{staticClass:"btn",attrs:{"to":{
                name: 'loginphone_en',
                params:{
                  package_id: this.id
                }
              }}},[_c('a',[_vm._v(" "+_vm._s(_vm.$t("continue"))+" "),_c('i',{class:_vm.$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'})])]):_c('router-link',{staticClass:"btn",attrs:{"to":{
                name: 'loginphone',
                params:{
                  package_id: this.id
                }
              }}},[_c('a',[_vm._v(" "+_vm._s(_vm.$t("continue"))+" "),_c('i',{class:_vm.$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'})])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }