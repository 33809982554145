<template>
    <section id="Second" v-if="!loading">
      <div class="container">
        <div  class="popup_payment" id="popup_payment">
    
    <div  class="payment_content text-center">
        <i class="bi bi-x-circle icon_close_payment" @click="clode_popup"></i>
        <img src='https://dekakeen.com/assets/image/logo.png' class="logo_popup"/>
        <h4>{{$t('Payment has not been made yet')}}</h4>
        <div style="margin:1%">
          <a  class="btn" @click="sliderLink()">
                  {{ $t("payment") }}
                  <i
                    :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
                  ></i>
                </a>
        </div>
    
    
</div>
</div>
<form @submit.prevent="sendInfo" @keydown="form.onKeydown($event)" autocomplete="off">
          <div class="row">
              <div class="col-lg-6 section1">
                <h3 class="title_invoice">{{ $t("marketinfo") }} </h3>
                <div class="d-flex flex-row justify-content-end loginBtn">
          <a href="https://dekakeen.com/dashboard/login_my_market">{{ $t('HaveAccount') }}</a>
        </div>
                  <div class="formcontainer2">

                           <!-- country -->

                           <div class="formcontainer2">
          <div class="row">
                    <div class="col-lg-6">
              <div class="input1" v-if="DBitems.types!=null">
                <h4>{{ $t("type") }} <span style="color:red"> *</span></h4>
                <v-select
                  v-model="form.type_id"
                  :reduce="(option) => option.id"
                  :options="DBitems.types"
                  label="name"
                  required
                  :placeholder="$t('type')"
                ></v-select>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input1" v-if="DBitems.countries!=null">
                <h4>{{ $t("country") }} <span style="color:red"> *</span></h4>
                <v-select
                  v-model="form.country_id"
                  :reduce="(option) => option.id"
                  :options="DBitems.countries"
                  label="city"
                  required
                  :placeholder="$t('country')"
                ></v-select>
              </div>
            </div>
            </div>
                        <!-- end country -->
                                <!-- market name -->
                                <div class="row">
            <div class="col-lg-6">
           
              <div class="input1">
                <h4>{{ $t("StoreNameAr") }} <span style="color:red"> *</span></h4>
                <input
                  id="nameAR"
                  class="input-field"
                  v-on:keypress="isLetter1($event)"
                  type="text"
                  name="nameAR"
                  autocomplete="off"
                  :placeholder="$t('StoreNameArex')"
                  v-model="form.name_ar"
                  required
                  maxlength="255"
                  minlength="3"
                  style="text-align: right;"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input1">
                <h4>{{ $t("StoreNameEn") }} <span style="color:red"> *</span></h4>
                <input
                  id="nameEN"
                  class="input-field"
                  v-on:keypress="isLetter($event)"
                  type="text"
                  name="nameEN"
                  autocomplete="off"
                  :placeholder="$t('StoreNameEnex')"
                  v-model="form.name_en"
                  required
                  maxlength="255"
                  minlength="3"
                  style="text-align: left;"
                />
              </div>
            </div>
           
         




<!--  -->
           
            


<div v-if="show1==true" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">{{ $t("Terms and Conditions") }}</slot>
          </div>
          <div class="container" v-html="pages.terms">
      <!--v-html=""-->
    </div>
          <div class="modal-footer">
            <slot name="footer">
              
              <button
                class="modal-default-button"
                @click="(show1 = false), $emit('close')"
              >
                {{ $t("close") }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>

    <div v-if="show2==true" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">{{ $t("Seller Agreement") }}</slot>
          </div>
          <div class="container" v-html="pages.saller">
      <!--v-html=""-->
    </div>
          <div class="modal-footer">
            <slot name="footer">
              
              <button
                class="modal-default-button"
                @click="(show2 = false), $emit('close')"
              >
                {{ $t("close") }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>

            
            <!--editttttt check if match-->
          
          </div>

                            <!-- slug -->
                            <!--  -->
              

<div class="row">
<div class="col-lg-8">
  <h4>{{ $t("StoreLink") }} <span style="color:red"> *</span></h4>
                <input
                  id="link"
                  class="input-field"
                  v-on:keypress="isLetterLink($event)"
                  type="text"
                  name="link"
                  autocomplete="off"
                  :placeholder="$t('Slug_hint')"
                  v-model="form.slug"
                  required
                  maxlength="255"
                  minlength="3"
                  style="font-size: 12px;"
                />
              

              </div>


              <div class="col-lg-4 ">
                <h4 style="color:transparent !important">{{ $t("StoreLink") }} <span style="color:red"> *</span></h4>
                <input
                  class="input-field flag_text"
                  type="text"
                 readonly
                  placeholder="https://dekakeen.com/store"
                
                />

                     </div>
            </div>



<!--  -->
<div class="col-lg-12">
              <div class="d-flex flex-row " style="margin-top:10px;">
              <input class="checkboxDomain" type="checkbox" id="checkbox" v-model="form.domain"  style="width:20px;margin:2.5%"/>
              <label for="checkbox">
               <h4 style="font-size:14px">{{ $t('checkboxDomain') }} {{ $t('checkboxDomain1') }}</h4>
               <p style="color: red;
    direction: ltr;
    text-align: right;">{{$t('domin description')}}</p>
              </label>
              </div>
            </div>

                            <!-- end slug -->






                                <div class="row">
            <div class="col-lg-6">
              <div class="input1">
                <h4>{{ $t("name") }} <span style="color:red"> *</span></h4>
                <input
                  id="name"
                  class="input-field"
                  type="text"
                  name="name"
                  autocomplete="false"
                  :placeholder="$t('EnterName')"
                  v-model="form.name"
                  required
                  maxlength="255"
                  minlength="3"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input1" v-if="!loading">
                <h4>{{ $t("inputThePhone") }}<span style="color:red"> *</span></h4>
                <input
                  style="min-height: 60px;"
                  dir="ltr"
                  required
                  fetch-country
                  show-code-on-list
                  no-example
                  :aria-valuemin="this.phone"
                  @update="sendPhone1($event)"
                  v-model="form.phone"
                  readonly
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="input1">
                <h4>{{ $t("Email") }} <span style="color:red"> *</span></h4>
                <input
                  class="input-field"
                  type="email"
                  name="email"
                  autocomplete="false"
                  v-model="form.email"
                  placeholder="example@gmail.com"
                  required
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input1">
                <h4>{{ $t("password") }} <span style="color:red"> *</span></h4>
                <input
                  id="password"
                  class="input-field"
                  type="password"
                  name="name"
                  autocomplete="new-password"
                  v-model="form.password"
                  :placeholder="$t('EnterPass')"
                  required
                  minlength="8"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input1">
                <h4>{{ $t("passwordConfirm") }} <span style="color:red"> *</span></h4>
                <input
                  id="passwordconfirm"
                  class="input-field"
                  type="password"
                  autocomplete="new-password"
                  name="name"
                  :placeholder="$t('EnterPass')"
                  required
                  v-model="form.confirmPassword"
                  minlength="8"
                />
              </div>
            </div>
            <!--editttttt check if match-->
            
          </div>

                                <!-- end market name -->
                   </div>

                   <!-- create2 -->
                
          
         
        </div>
                   <!-- end create2 -->
                   <!-- end col6 -->
              </div>
                  <!-- detail in voice -->
              <div class="col-lg-6 section2">

                <h3 class="title_invoice">{{ $t("invoice") }}</h3>
                <!-- use coupon -->
                <div class="mb-4 mt-2">
          <h6 class="title_coupon">{{ $t("Coupon") }}</h6>
          <div class="row">
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control code-input"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                v-model="disCode"
                :placeholder="$t('Couponcode')"
              />
              <span>
                <i
                  style="color: #24b300; font-size: 15px; margin-left: -75%;display:none"
                  class="bi bi-check-circle-fill"
                ></i>
              </span>
            </div>
            <div class="col-sm-4" style="margin-top: -7%!important;">
              <a class="btn" @click="Couponcode()">
                {{ $t("Activate") }}
                <i
                  :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
                ></i>
              </a>
            </div>
          </div>
          <span>{{ couponMsg }}</span>
        </div>
                <!-- end use coupon -->
              <table class="col-lg-8">
                  <thead>
                  <tr>
  <th>{{ $t('package') }}</th>
  <th>{{ $t('total') }}</th>
 
     </tr>
     </thead>
     <tbody>
     <tr v-if="this.package">
     <td >{{ this.package.name }}</td>
     <td>{{ this.package.price }}</td>
     </tr>

     <tr v-if="this.form.discount_price!=0">
     <td >{{ $t('discount') }}  ({{ this.form.discount }} %)</td>
     <td>{{ (this.form.discount_price).toFixed(2) }} {{ $t('unit') }}</td>
     </tr>
     <tr v-if="this.form.part_price!=0">
     <td >{{ $t('price') }} </td>
     <td>{{ (this.form.part_price).toFixed(2) }} {{ $t('unit') }}</td>
     </tr>
     <tr v-if="this.form.tax!=0">
     <td >{{ $t('tax') }} ({{ this.form.tax}})%</td>
     <td>{{ (this.form.tax_price).toFixed(2) }} {{ $t('unit') }}</td>
     </tr>
     <tr v-if="this.form.total_price!=0">
     <th >{{ $t('total') }}</th>
     <th>{{ (this.form.total_price).toFixed(2) }} {{$t('unit') }}</th>
     </tr>


     </tbody>

                  </table>
                 
                 

                  <div class="d-flex flex-row justify-content-start">
              <button type="submit" :disabled="form.busy" class="btn">
                {{ $t("payment") }}
                <i
                  :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
                ></i>
              </button>
             
            </div>

        <div v-if="validateMessage!=''" style="text-align: center; margin-top: 10px;">
          <span style="color: red !important; margin: 0;">{{ validateMessage }}*</span>
        </div>
              </div>
              <!-- detail invoice -->
              </div>

       
        
      </form>
      </div>
    </section>
  </template>
    <script>
    import VuePhoneNumberInput from "vue-phone-number-input";
    import "vue-phone-number-input/dist/vue-phone-number-input.css";
    
    import Form from "vform";
    
    export default {
      components: { VuePhoneNumberInput },
      data: function() {
        return {
          package: 
          {'name':'test',
          'price':0.00,
          'cost':0.00
          },
          isValid: false,
          partphone: null,
          loading: true,
          validateMessage: "",
          DBitems: null,
          disCode: null,
          errorMessage: "",
          show1: false,
          show2: false,
          show3: false,
          show4: false,
          couponMsg: '',
          form: new Form({
            country_id: null,
          type_id: null,
          unit_id: null,
          logo: "",
          name_en: null,
          domain: false,
          name_ar: null,
          slug: null,
          name: null,
          email: null,
          password: null,
          confirmPassword: null,
          phone: null,
          package_id: null,
          price: 0,
          price_domin: 0,
          lat: null,
          long: null,
          accept: null,
          discount: null,
          discount_id: null,
          tax: 0,
          tax_id: 0,
          total_price: 0,
          part_price: 0,
          tax_price: 0,
          discount_price: 0,
          })
        };
      },
      props: {
        package_id: Number,
        phone: String
      },
      created() {
        
//failed payment api request

//get info market failed
      this.axios
      .get("https://etrolley.net/api/website/failed_market/"+this.$route.params.id, {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => {
        if (response.data.code == "-1") {
          this.validateMessage = response.data.message;
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        } else if (response.data.code == "1") {
          this.market = response.data.data;
           console.log('***market',this.market.name_en)
          //data market
          this.form.name_ar=this.market.name_ar;
          this.form.name_en=this.market.name_en;
          this.form.slug=this.market.slug;
          this.form.domain=this.market.domain;
          this.form.type_id=this.market.type_id;
          this.form.country_id=this.market.country_id;
          this.form.package_id=this.market.package_id;
          this.form.name=this.market.user_name;
          this.form.email=this.market.email;
          this.form.phone=this.market.phone;
          this.form.password=this.market.password;
          this.form.confirmPassword=this.market.password;
          this.form.lat=this.market.lat;
          this.form.long=this.market.long;
          this.form.discount=this.market.discount;
          this.form.discount_id=this.market.discount_id;
          this.form.tax=this.market.tax;
          this.form.tax_id=this.market.tax_id;
          this.link=this.market.link;
          this.form.part_price=this.market.part_price;
          this.form.total_price=this.market.total_price;
        }
      })
      .then(()=>{this.loading = false});
//end failed payment api request




      {
         this.loading = false;
         this.loading = true;
    
         //package info
         this.axios
        .get("https://dekakeen.com/api/website/get_package/"+this.form.package_id, {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.package = response.data.data;
         // console.log('package  ' ,response.data.data)
        
    
        });
      //  console.log('##########form price22222    ',this.form.price);
      this.axios
        .get("https://dekakeen.com/api/website/create_market", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          if (response.data.code == "-1") {
            this.validateMessage = response.data.message;
            setTimeout(() => {
              this.validateMessage = "";
            }, 3000);
          } else if (response.data.code == "1") {
            this.DBitems = response.data.data;
           /* this.form.tax=this.DBitems.tax;
            console.log('tax****',this.form.tax,this.package.cost)
            this.form.discount_price=0;
            this.form.price=this.package.cost;
            this.form.tax_price=(this.form.tax*0.01)*this.package.cost;
            this.form.total_price=this.package.cost+this.form.tax_price;
            this.form.part_price=this.package.cost;*/
          }
        })
        .then(()=>{this.loading = false});
        this.getlatlan();
    
        // pages
        this.axios
        .get("https://dekakeen.com/api/website/pages_terms", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.pages = response.data.data;
        })
    
       
    
        }


    
  
        
      },
      mounted() {
       /* if (this.package_id != null&&this.phone != null) {
        this.loading = true;
        if (this.$i18n.locale == "ar") {
        
        } else {
         
        }
        this.loading = false;
        }*/
      },
      computed: {
        myState: function() {
          return this.$store.state.langchange; // return the state value in `my_state`
        }
      },
      watch: {
        myState: function() {
          console.log("editttttttttttttttttt");
          this.loading = true;
          if (this.$i18n.locale == "ar") {
            document
              .getElementsByClassName("input-tel__input")[0]
              .setAttribute("placeholder", "رقم الهاتف");
            document.getElementsByClassName(
              "country-selector__label"
            )[0].innerHTML = "رمز الدولة";
            document.getElementsByClassName("input-tel__label")[0].innerHTML =
              "* رقم الهاتف";
          } else {
            document
              .getElementsByClassName("input-tel__input")[0]
              .setAttribute("placeholder", "phone number");
            document.getElementsByClassName(
              "country-selector__label"
            )[0].innerHTML = "country code";
            document.getElementsByClassName("input-tel__label")[0].innerHTML =
              "phone number *";
          }
          this.loading = false;
        }
      },
      methods: {
        sliderLink(){
      window.location.href= 'https://dekakeen.com/payment/e_pay_website/'+this.$route.params.id+'/v1'
    },
        clode_popup()
    {
      document.getElementById('popup_payment').style='display:none';
    },
      Couponcode() {
        this.loading = true;
        console.log('***coupon*****')
        this.axios
          .post(
    
              "https://dekakeen.com/api/website/use_coupon",
            {
              code: this.disCode,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + '',
              },
            }
          )
          .then((response) => {
            if (response.data.code == "1") {
              this.couponMsg = response.data.message+' '+response.data.data.discount+'%';
              this.form.discount = response.data.data.discount;
              this.form.discount_id = response.data.data.id;
              this.form.part_price=this.package.cost;
              this.form.total_price=this.form.part_price;
    if(this.form.discount!=0)
    {
  
    this.form.discount_price=this.form.part_price*(this.form.discount/100);
    console.log('discount   form',this.form.discount_price)
  
    
    this.form.part_price=this.form.part_price-this.form.discount_price;
      this.form.total_price=this.form.part_price;
    }
    if(this.form.tax>0)
    {
    this.form.tax_price=this.form.part_price*(this.form.tax/100);
    
    this.form.total_price=this.form.part_price+(this.form.part_price*(this.form.tax/100));
    }
    
    
        
              console.log('test*********');
            } else {
              this.couponMsg = response.data.message;
            }
    
    
            //////////////////
            
           
          })
          .then((this.loading = false))
          .catch((error) => {
            // if (error.response.status == "401") {
            //   this.$localStorage.remove(this.$store.storeName);
            //   window.location.href =
            //     "/" ;
            // }
          });
      },
      async sendInfo() {
          this.form.accept=true;
        if(this.form.accept==true)
        {
          this.loading = true;
        await this.form
          .post("https://dekakeen.com/api/website/store_market/v1", {
            form: this.form,
            //country: this.form.country,
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          })
          .then(response => {
            if (response.data.code == "-1") {
              this.validateMessage = response.data.message;
              setTimeout(() => {
                this.validateMessage = "";
              }, 3000);
            } else if (response.data.code == "1") {
              window.location.href = response.data.data.link;
              /*this.$router.push({
                  name: "activateCode",
                  params: {
                    storeName: this.$store.storeName,
                    phone: this.form.phone
                  }
                });*/
              console.log(response.data);
              //console.log( response.data.message);
            }
          })
          .then(()=>{this.loading = false});
        }
        else
        {
          if(this.$i18n.locale=='en')
          this.validateMessage='Please Agree To The Terms And Conditions And Seller Agreement';
        else
        this.validateMessage='يرجى الموافقة على الشروط و الأحكام واتفاقية البائع';
        }
      },
      updatedata() {
        this.loading = true;
        this.axios
          .get("https://dekakeen.com/api/website/create_market", {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          })
          .then(response => {
            if (response.data.code == "-1") {
              this.validateMessage = response.data.message;
              setTimeout(() => {
                this.validateMessage = "";
              }, 3000);
            } else if (response.data.code == "1") {
              this.DBitems = response.data.data;
            }
          })
          .then(()=>{this.loading = false});
          this.getlatlan();
        this.$store.state.langchange = false;
      },
      readURL(e) {
        // this.form.logo = URL.createObjectURL(e.target.files[0]);
        var ratio = 0;
        var image = new Image();
        var self = this;
        image.src = URL.createObjectURL(e.target.files[0]);
        //Validate the File Height and Width.
        image.onload = function() {
          //var height = this.height;
          //var width = this.width;
          //if (height > width) ratio = width / height;
          //else ratio = height / width;
          /////////alert(height + " " + width + " " + ratio);
          // if (Math.round(ratio) != 1) {
          //   if (self.$i18n.locale == "en") {
          //     alert("Invalid image You must enter a square image");
          //   } else {
          //     alert("الصورة غير صالحة يجب إدخال صورة مربعة");
          //   }
          // } else {
            self.image = URL.createObjectURL(e.target.files[0]);
            self.form.logo = e.target.files[0];
          //}
        };
      },
      isLetter(e) {
        let char = String.fromCharCode(e.keyCode); // Get the character
        if (/^[A-Za-z0-9 /s]+$/.test(char)) return true;
        // Match with regex
        else e.preventDefault(); // If not match, don't add to input text
      },
      isLetter1(e) {
        let char = String.fromCharCode(e.keyCode); // Get the character
        if (/^[0-9ء-ي ]+$/.test(char)) return true;
        // Match with regex
        else e.preventDefault(); // If not match, don't add to input text
      },
      isLetterLink(e){
        let char = String.fromCharCode(e.keyCode); // Get the character
        if (/^[A-Za-z0-9_s]+$/.test(char)) 
        
        return true;
        // Match with regex
        else e.preventDefault(); // If not match, don't add to input text
      },
      getlatlan(){
        navigator.geolocation.getCurrentPosition((position) => {  
          this.form.lat= position.coords.latitude;
          this.form.long= position.coords.longitude;   
          console.log("  lan:  "+ this.form.lat +"  long:  "+ this.form.long)
        });
        //console.log( this.form.lat +"  n  "+ this.form.long)
      }
    }
    };
    </script>
    
    
    <style scoped>
    /*--------------------------------------------------------------
    # Hero Section
    --------------------------------------------------------------*/
    #Second {
      padding-top: 100px;
      min-height: 700px;
      background-color: #fdfefe;
    }
    .input1 {
      width: 100%;
      margin-top: 30px;
    }
    #Second {
      margin-top: 70px;
    }
    
    input {
      border: 0;
      border: 2px solid #eae9f2;
      background-color: #fff;
      width: 100%;
      height: 32px;
      border-radius: 15px;
      color: var(--secondary-color);
      padding: 25px 10px 30px 10px;
    }
    
    h3,h5 {
      font-family: "GraphikArabic-Bold";
      font-size: 2em;
      line-height: 40px;
      background: linear-gradient(
        45deg,
        var(--primary-color),
        #099168,
        var(--primary-color)
      );
      background-repeat: round;
      background-size: 80%;
      animation: animate 10s linear infinite;
      -webkit-background-clip: text;
      -webkit-text-fill-color: rgba(255, 255, 255, 0);
      text-align: center;
      margin-bottom: 30px;
    }
    h4 {
      font-size: 16px;
      font-family: "GraphikArabic-Medium";
      color: #696871;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    
    .btn {
      /*border: 2px solid var(--primary-color);*/
      background-color: var(--third-color);
      border-radius: 30px;
      padding: 10px 8px 10px 30px;
      font-size: 18px;
      font-family: "GraphikArabic-Medium";
      color: var(--primary-color);
      font-weight: 700;
      margin-top: 40px;
      text-decoration: none;
      z-index: 200;
    }
    .btn:hover {
      transform: scale(1.05);
      background-color: var(--third-color);
    }
    [dir="rtl"] .btn {
      padding: 10px 30px 10px 8px;
    }
    .btn i {
      font-size: 25px;
      background-color: #ffe9c8;
      border-radius: 50px;
      padding: 4px 14px;
      margin: 0 0 0 10px;
    }
    [dir="rtl"] .btn i {
      margin: 0 10px 0 0;
    }
    
    .icon {
      margin-top: -120px;
    }
    .formcontainer {
      width: 60%;
      margin: 0 20%;
    }
    .loginBtn a {
      text-decoration: underline !important;
      text-underline-offset: 5px;
      font-size: 16px;
      font-family: "GraphikArabic-Medium";
      color: #696871;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    /*@media (min-width: 1400px){
    .container {
        max-width: 1200px !important;
    }
    }*/
    @media (max-width: 992px) {
      #Second {
      padding-top: 55px;
    }
      h4 {
        font-size: 15px;
      }
      .card-img-actions {
        margin-top: 40px;
      }
      .input1 {
        width: 60%;
        margin-left: 20%;
        margin-right: 20%;
        margin-top: 20px;
      }
      .button {
        width: 50%;
        margin-left: 25%;
        margin-right: 25%;
      }
      .formcontainer {
        width: 100%;
        margin: 0;
      }
    }
    @media (max-width: 772px) {
      .input1 {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 20px;
      }
      .button {
        width: 60%;
        margin-left: 20%;
        margin-right: 20%;
      }
    
      h3.title_invoice
      {
          text-align:left !important;
      }
      h3.title_invoice
      {
          text-align:left !important;
      }
      [dir="rtl"] h3.title_invoice
      {
          text-align:right !important;
      }
    }
    table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    }
    
    [dir="ltr"] td, th {
    text-align: left;
    padding: 8px;
    }
    
    tr:nth-child(even) {
      background-color: #28997633;
    }
    
    [dir="rtl"] td,th {
      text-align: right ;
    }
    th {
      font-size: 22px !important;
      color: var(--primary-color);
      background-color: #28997633;
    
    }
    table{
     
      /*margin-top: 12%;*/
    }
    
    .loginBtn a[data-v-1196d8fa] {
     
      margin-top: -45px;
    }
    [dir="rtl"] .col-lg-4.input-field {
      margin-right: -33px;
      margin-left: 0px;
    }
    .col-lg-4.input-field {
      margin-left: -33px;
      margin-right: 0px;
    }
    
    input.input-field.flag_text {
      border-radius: 15px 5px 5px 15px;
    }
    .activate_btn
    {
    border-radius: 15px;
      cursor: pointer;
      background-color: var(--third-color);
      padding: 10% 25%;
      text-align: center;
      border: 2px solid var(--primary-color);
      border-raduies: 25px;
      margin-top: 10px;
    
    }
    .title_coupon
    {
    font-size: 16px;
      font-family: "GraphikArabic-Medium";
      color: #696871;
      font-weight: 600;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .popup_payment
{
    background-color: rgba(18,29,47,.75);
    bottom: 0;
    height: 100%;
    right: 0;
    box-shadow: 0 0 5px 5px rgb(0 0 0 / 10%);
    /* right: 0; */
    opacity: 1;
    overflow: hidden;
    position: fixed;
    /* right: 0; */
    top: 0;
    /* transition: all .35s cubic-bezier(.2,1,.3,1); */
    /* visibility: hidden; */
    display: block;
    width: 100%;
    z-index: 1062;
}

.payment_content {
    margin: 10% 33%;
    float: center;
    /* max-width: 300px; */
    /* padding: 10px; */
    background-color: white;
    background: #fff;
    /* box-shadow: 0 0 5px 5px rgb(0 0 0 / 10%); */
    height: fit-content;
    /* left: 0px; */
    left: 0px;
    opacity: 1;
    overflow-x: hidden;
    /*overflow-y: scroll;*/
    /* padding: 20px 15px; */
    /* position: fixed; */
    top: 0;
    transition: all .35s cubic-bezier(.2,1,.3,1);
    transition-duration: .75s;
    width: 550px;
        border-radius: 15px;
}
.logo_popup
{
  width: 200px;
    margin: 6%;
}
.payment_content h4
{
    font-weight:bold;
    color:#333333c2;
    font-size:18px;
    margin: 8px;
}
.loginBtn a {
    margin-top: -64px;
}
    </style>
    