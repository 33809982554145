<template>
  <header v-if="!loading" id="header" class="fixed-top d-flex align-items-center">
    <div
      class="container d-flex align-items-center justify-content-between"
      v-if="!this.$store.userTokenState"
    >
      <div class="logo mobile-inactive logo-1">
        <img  src="https://dekakeen.com/assets/image/logo.png" 
                loading="lazy"
                 alt="Dekakeen ,"
                 width="100%"
                 height="100%"
        />
       
      </div>
      <nav id="navbar" :class="'navbar' + (activeMobile ? ' navbar-mobile' : '')">
        <i
          :class="
            'bi bi-list mobile-nav-toggle ' + (activeMobile ? ' bi-x' : '')
          "
          @click="activeMobile = !activeMobile"
        ></i>
        <ul>
          <li class="mobile-active">
            <div >
              <div
                class="logo d-flex flex-row justify-content-center align-items-center"
                style="height: 70px; margin-bottom: 20px"
              >
                <img src="https://dekakeen.com/assets/image/logo.png" 
                
                loading="lazy"
                 alt="Dekakeen "
                 width="100%"
                 height="100%"
                 />
              </div>
            </div>
          </li>
         
          <li  v-if="$i18n.locale == 'en'"  @click="activeMobile = !activeMobile">
            <router-link
              :to="{
                name: 'home_en',
              }"
            >{{ $t("home") }}</router-link>
          </li>


          <li  v-else  @click="activeMobile = false">
            <router-link
              :to="{
                name: 'home',
              }"
            >{{ $t("home") }}</router-link>
          </li>
           <li  v-if="$i18n.locale == 'en'">
            <router-link
              :to="{
                name: 'about_en',
              }"
            >{{ $t("about-us") }}</router-link>
          </li>


          <li v-else  @click="activeMobile = false">
            <router-link
              :to="{
                name: 'about',
              }"
            >{{ $t("about-us") }}</router-link>
          </li>
          <li v-if="$i18n.locale == 'en'">
            <router-link
              :to="{
                name: 'pricing_en',
              }"
            >{{ $t("pricing") }}</router-link>
          </li>


          <li v-else  @click="activeMobile = false">
            <router-link
              :to="{
                name: 'pricing',
              }"
            >{{ $t("pricing") }}</router-link>
          </li>
          <li v-if="$i18n.locale == 'en'"  @click="activeMobile = false">
            <router-link
              :to="{
                name: 'privacy_en',
              }"
            >{{ $t("privacyPolicy") }}</router-link>
          </li>

          <li v-else  @click="activeMobile = false">
            <router-link
              :to="{
                name: 'privacy',
              }"
            >{{ $t("privacyPolicy") }}</router-link>
          </li>
           <li v-if="$i18n.locale == 'en'">
            <router-link
              :to="{
                name: 'questions_en',
              }"
            >{{ $t("questions") }}</router-link>
          </li>


          <li v-else  @click="activeMobile = false">
            <router-link
              :to="{
                name: 'questions',
              }"
            >{{ $t("questions") }}</router-link>
          </li>


          <li  v-if="$i18n.locale == 'en'"  @click="activeMobile = false">
            <router-link
            :to="{
            name: 'blogs_en',
          }"
            >{{ $t("blogs") }}</router-link>
          </li>


          <li v-else  @click="activeMobile = false">
            <router-link
            :to="{
            name: 'blogs',
          
          }"
            >{{ $t("blogs") }}</router-link>
          </li>


          <li  v-if="$i18n.locale == 'en'"  @click="activeMobile = false">
            <router-link
            :to="{
            name: 'contactus_en',
          }"
            >{{ $t("contactus") }}</router-link>
          </li>


          <li v-else  @click="activeMobile = false">
            <router-link
            :to="{
            name: 'contactus',
          
          }"
            >{{ $t("contactus") }}</router-link>
          </li>
          <li class=" mobile-active "  @click="activeMobile = false">
              <button v-if="$i18n.locale == 'ar'" @click="onChange('en')">
                English
              </button>
              <button v-if="$i18n.locale == 'en'" @click="onChange('ar')">
                عربي
              </button>
            </li>
            <li v-if="$i18n.locale == 'en'" class="mobile-active"  @click="activeMobile = false">
              <router-link
                :to="{
                name: 'loginphone',
                params: { storeName: this.$store.storeName },
              }"
              >{{ $t("CreateAccount") }}</router-link>
            </li>

            <li v-else class="mobile-active"  @click="activeMobile = false">
              <router-link
                :to="{
                name: 'loginphone_en',
                params: { storeName: this.$store.storeName },
              }"
              >{{ $t("CreateAccount") }}</router-link>
            </li>
       
         
        </ul>
        <!-- .navbar -->
      </nav>
      <div>
        <nav class="navbar">
          <ul>
            <li class="LangBtn"  v-if="$i18n.locale == 'ar'" @click="onChange('en')">
              <button>
                English
              </button>
            </li>
            <li class="LangBtn"  v-if="$i18n.locale == 'en'" @click="onChange('ar')">
              <button>
               عربي
              </button>
            </li>
            <li  v-if="$i18n.locale == 'en'"  class="createAccountLI"  @click="activeMobile = false">
              <router-link
              class="createAccount"
                :to="{
                name: 'loginphone_en',
              }"
              >{{ $t("CreateAccount") }}</router-link>
            </li>


            <li  v-else  class="createAccountLI"  @click="activeMobile = false">
              <router-link
              class="createAccount"
                :to="{
                name: 'loginphone',
              }"
              >{{ $t("CreateAccount") }}</router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="logo mobile-active">
        <img src="https://dekakeen.com/websiteImages/logo.png"  
                 loading="lazy"
                 alt="Dekakeen ,"
                 width="100%"
                 height="100%" />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  // eslint-disable-next-line
  /*eslint-disable */
  name: "Header",
  props: {
    // name: String,
    // image: String
    storeName: String
  },
  data: function() {
    return {
      loading: false,
      loadingNote: false,
      activeMobile: false,
      word: "",
      notifications: [],
      lang:"",
      home:'home_en'
    };
  },

  methods: {
    
    onChange(lang) {

    
      this.$store.state.langchange = true;
      this.$store.state.langchange1 = true; //for footer
      this.$i18n.locale = lang;


const last = this.$route.name.substr(this.$route.name.length -3,this.$route.name.length - 1);
console.log(last); 
       if(last=="_en")
       {
              var page_name=
              this.$route.name.substr(0,this.$route.name.length -3)
        this.$router.push({name: page_name});
       }

       else
       {
        var page_name=
              this.$route.name+'_en'
        this.$router.push({name: page_name});
       }
    // this.$router.push({name: this.$route.name});
      if (lang == "ar") {
        document.documentElement.setAttribute("dir", "rtl");
        let h = document.querySelector('html');
        h.setAttribute('lang', 'ar');
        //change meta
        document.getElementById("description").content=
        this.$store.state.description_ar;


        document.getElementById("keyword_meta").content=this.$store.state.keyword_ar;

        this.lang='ar'

        document.title = this.$store.state.title_ar;

      } else {
        let h = document.querySelector('html');
        h.setAttribute('lang', 'ar');
        document.documentElement.setAttribute("dir", "ltr");
        this.lang=''
        document.title = this.$store.state.title_en;
        document.getElementById("description").content=
        this.$store.state.description_en;


        document.getElementById("keyword_meta").content=this.$store.state.keyword_en;
      }

    },
  
    searchRoute() {
      if (this.$route.path.includes("/" + this.$store.storeName + "/Search")) {
        console.log(this.word + "hiiiiiii");
        this.$router.push({ name: "search", params: { word: this.word } });
      } else {
        this.$router.push({
          name: "search",
          params: { storeName: this.$store.storeName, word: this.word }
        });
      }
    }
   
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
[dir="rtl"] .bi-search {
  margin-right: 72%;
  margin-top: 6px;
  position: absolute;
}
[dir="ltr"] .bi-search {
  margin-left: 72%;
  margin-top: 6px;
  position: absolute;
}

.filterDropdown ul,
li {
  list-style: none;
}
.filterDropdown ul {
  display: block;
  position: absolute;
  left: 5px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 15px;
  min-height: 190px;
}

/*.navbar .dropdown ul li,*/

.filterDropdown ul li {
  min-width: 200px;
}

/*.navbar .dropdown ul a,*/

.filterDropdown ul a {
  padding: 10px 20px;
  color: #2a2c39;
  margin: 0 5px;
  font-size: 14px;
}

/*.navbar .dropdown ul a i,*/

.filterDropdown ul a i {
  font-size: 12px;
}

/*.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a,*/

.filterDropdown ul a:hover,
.filterDropdown ul .active:hover,
.filterDropdown ul li:hover > a {
  color: var(--primary-color);
}

/*.navbar .dropdown:hover>ul,*/

.filterDropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

/*.navbar .dropdown .dropdown ul,*/

.filterDropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

/*.navbar .dropdown .dropdown:hover>ul,*/

.filterDropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
button {
  background-color: transparent;
  border: 0;
  /*border: 1px solid var(--primary-color);
  border-radius: 15px;
  padding: 10px;*/
}
.createAccountLI{
  padding: 0;
}
.createAccount{
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 25px;        
  padding: 5px 15px 7px 15px;
  cursor: pointer;  
  color: #fff;
}

.createAccount:hover,
.createAccount:focus{
 border: 2px solid var(--third-color) !important;
 background-color: var(--third-color);
 transform: scale(1.02);
 color: var(--primary-color) !important;
}
.LangBtn{
  background-color: var(--third-color);
  border-radius: 25px;        
  cursor: pointer;  
  margin: auto 10px;
}
.LangBtn:hover{
  /*background-color: #fff;
  border: 2px solid var(--primary-color);*/
  transform: scale(1.02);
}
@media (min-width: 992px) {
  .logo-1{
    min-width: 230px;
  }
}
@media (max-width: 1200px) and (min-width: 992px) {
  .navbar li {
    padding: 5px 7px 7px 7px;
  }
  .navbar a {
    padding: 5px 5px;
  }

  /* #header .logo img {
    max-height: 30px;
  }*/
}
@media (min-width: 992px) {

    .logo-1[data-v-61dd7a3d] {
        min-width: 150px;
    }
}
</style>
